import React from "react";
import {HANDKANASTER, JOKERHANDKANSTER, JOKERKANASTER} from "./Kana";


class AddKanaEntry extends React.Component {

    state = {
        gameNumber: this.props.kanaList.length + 1,
        listId: this.props.listId,
        rein1: "0",
        unrein1: "0",
        hand1: "0",
        tisch1: "0",
        dreier1: "0",
        sonstiges1: "0",
        rein2: "0",
        unrein2: "0",
        hand2: "0",
        tisch2: "0",
        dreier2: "0",
        sonstiges2: "0",
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.sendKanaEntry(this.state);
        this.props.changeGameFlag(false);
    };


    numberImg = (number) => {
        switch (number) {
            case "0":
                return <img src="./images/numbers/zero.png"
                            className="adderNumber1"
                            alt="0"/>;
            case "1":
                return <img src="./images/numbers/one.png"
                            className="adderNumber1"
                            alt="1"/>;
            case "2":
                return <img src="./images/numbers/two.png"
                            className="adderNumber1"
                            alt="2"/>;
            case "3":
                return <img src="./images/numbers/three.png"
                            className="adderNumber1"
                            alt="3"/>;
            case "4":
                return <img src="./images/numbers/four.png"
                            className="adderNumber1"
                            alt="4"/>;
            default:
                return <div/>;
        }
    };

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div style={{display:'flex', justifyContent:'center'}}>
                    <div className="flexAdderContainer KanaAdderForm">
                        <div className="alignBottom">
                            <div>Team1</div>
                            <div>Team2</div>
                        </div>
                        <div>
                            <div>unrein</div>
                            <div>
                                <input
                                    type="number"
                                    min="0" max="22"
                                    value={this.state.unrein1}
                                    onClick={(e) => {if(e.target.value === "0") e.target.value = ""}}
                                    onChange={(e) => this.setState({unrein1: e.target.value})}
                                />
                            </div>
                            <div>
                                <input
                                    type="number"
                                    min="0" max="22"
                                    value={this.state.unrein2}
                                    onClick={(e) => {if(e.target.value === "0") e.target.value = ""}}
                                    onChange={(e) => this.setState({unrein2: e.target.value})}
                                />
                            </div>
                        </div>
                        <div>
                            <div>rein</div>
                            <div>
                                <input
                                    type="number"
                                    min="0" max="22"
                                    value={this.state.rein1}
                                    onClick={(e) => {if(e.target.value === "0") e.target.value = ""}}
                                    onChange={(e) => this.setState({rein1: e.target.value})}
                                />
                            </div>
                            <div>
                                <input
                                    type="number"
                                    min="0" max="22"
                                    value={this.state.rein2}
                                    onClick={(e) => {if(e.target.value === "0") e.target.value = ""}}
                                    onChange={(e) => this.setState({rein2: e.target.value})}
                                />
                            </div>
                        </div>
                        <div>
                            <div>Joker</div>
                            <div style={{marginBottom: '2px'}}
                                 onClick={() => {
                                     switch (this.state.sonstiges1) {
                                         case "0":
                                             this.setState({sonstiges1: JOKERKANASTER});
                                             break;
                                         case HANDKANASTER:
                                             this.setState({sonstiges1: JOKERHANDKANSTER});
                                             break;
                                         case JOKERKANASTER:
                                             this.setState({sonstiges1: "0"});
                                             break;
                                         case JOKERHANDKANSTER:
                                             this.setState({sonstiges1: HANDKANASTER});
                                             break;
                                         default:
                                             console.log("damn, unexpected error :'(");
                                     }
                                 }}
                            >
                                {this.state.sonstiges1 === JOKERKANASTER || this.state.sonstiges1 === JOKERHANDKANSTER
                                    ? this.numberImg("1")
                                    : this.numberImg("0")}

                            </div>
                            <div
                                onClick={() => {
                                    switch (this.state.sonstiges2) {
                                        case "0":
                                            this.setState({sonstiges2: JOKERKANASTER});
                                            break;
                                        case HANDKANASTER:
                                            this.setState({sonstiges2: JOKERHANDKANSTER});
                                            break;
                                        case JOKERKANASTER:
                                            this.setState({sonstiges2: "0"});
                                            break;
                                        case JOKERHANDKANSTER:
                                            this.setState({sonstiges2: HANDKANASTER});
                                            break;
                                        default:
                                            console.log("damn, unexpected error :'(");
                                    }
                                }}
                            >
                                {this.state.sonstiges2 === JOKERKANASTER || this.state.sonstiges2 === JOKERHANDKANSTER
                                    ? this.numberImg("1")
                                    : this.numberImg("0")}

                            </div>
                        </div>
                        <div>
                            <div>Hand</div>
                            <div>
                                <input
                                    className="redColor"
                                    type="number"
                                    min="0" max="9999"
                                    value={this.state.hand1}
                                    onClick={(e) => {if(e.target.value === "0") e.target.value = ""}}
                                    onChange={(e) => this.setState({hand1: e.target.value})}
                                />
                            </div>
                            <div>
                                <input
                                    className="redColor"
                                    type="number"
                                    min="0" max="9999"
                                    value={this.state.hand2}
                                    onClick={(e) => {if(e.target.value === "0") e.target.value = ""}}
                                    onChange={(e) => this.setState({hand2: e.target.value})}
                                />
                            </div>
                        </div>
                        <div>
                            <div>Tisch</div>
                            <div>
                                <input
                                    className="greenColor"
                                    type="number"
                                    min="0" max="9999"
                                    value={this.state.tisch1}
                                    onClick={(e) => {if(e.target.value === "0") e.target.value = ""}}
                                    onChange={(e) => this.setState({tisch1: e.target.value})}
                                />
                            </div>
                            <div>
                                <input
                                    className="greenColor"
                                    type="number"
                                    min="0" max="9999"
                                    value={this.state.tisch2}
                                    onClick={(e) => {if(e.target.value === "0") e.target.value = ""}}
                                    onChange={(e) => this.setState({tisch2: e.target.value})}
                                />
                            </div>
                        </div>
                        <div>
                            <div>3er</div>
                            <div>
                                <input
                                    type="number"
                                    min="0" max="4"
                                    value={this.state.dreier1}
                                    onClick={(e) => {if(e.target.value === "0") e.target.value = ""}}
                                    onChange={(e) => {
                                        this.setState({
                                            dreier1: e.target.value,
                                            dreier2: (parseInt(this.state.dreier2) + parseInt(e.target.value)) > 4 ? 4 - parseInt(e.target.value) : this.state.dreier2
                                        })
                                    }}
                                />
                            </div>
                            <div>
                                <input
                                    type="number"
                                    min="0" max="4"
                                    value={this.state.dreier2}
                                    onClick={(e) => {if(e.target.value === "0") e.target.value = ""}}
                                    onChange={(e) => {
                                        this.setState({
                                            dreier2: e.target.value,
                                            dreier1: (parseInt(this.state.dreier1) + parseInt(e.target.value)) > 4 ? 4 - parseInt(e.target.value) : this.state.dreier1
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            <div>Handkanaster</div>
                            <div style={{border: 'solid 1px black', marginTop: '2px', marginBottom: '2px', cursor:'pointer'}}
                                 onClick={() => {
                                     switch (this.state.sonstiges1) {
                                         case "0":
                                             this.setState({sonstiges1: HANDKANASTER});
                                             break;
                                         case HANDKANASTER:
                                             this.setState({sonstiges1: "0"});
                                             break;
                                         case JOKERKANASTER:
                                             this.setState({sonstiges1: JOKERHANDKANSTER});
                                             break;
                                         case JOKERHANDKANSTER:
                                             this.setState({sonstiges1: JOKERKANASTER});
                                             break;
                                         default:
                                             console.log("damn, unexpected error :'(");
                                     }
                                 }}
                            >
                                {this.state.sonstiges1 === JOKERHANDKANSTER || this.state.sonstiges1 === HANDKANASTER ? "+1000" : "nein"}
                            </div>
                            <div style={{border: 'solid 1px black', cursor:'pointer'}}
                                 onClick={() => {
                                     switch (this.state.sonstiges2) {
                                         case "0":
                                             this.setState({sonstiges2: HANDKANASTER});
                                             break;
                                         case HANDKANASTER:
                                             this.setState({sonstiges2: "0"});
                                             break;
                                         case JOKERKANASTER:
                                             this.setState({sonstiges2: JOKERHANDKANSTER});
                                             break;
                                         case JOKERHANDKANSTER:
                                             this.setState({sonstiges2: JOKERKANASTER});
                                             break;
                                         default:
                                             console.log("damn, unexpected error :'(");
                                     }
                                 }}
                            >
                                {this.state.sonstiges2 === JOKERHANDKANSTER || this.state.sonstiges2 === HANDKANASTER ? "+1000" : "nein"}
                            </div>
                        </div>

                        <div style={{paddingTop: '8px'}}>
                            <button
                                type="submit"
                                className="btn btn-success"
                                style={{height: '100%'}}
                            >
                                <i className="fas fa-file-medical"/>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }

}

export default AddKanaEntry;

