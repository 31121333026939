import {HANDKANASTER, JOKERHANDKANSTER, JOKERKANASTER} from "./Kana";

export function sumKanaPoints(list) {
    if (list.length === 0) {
        return []
    }

    let gameArr = [];
    let old1 = 0, old2 = 0;

    for(let i = 0; i < list.length; i++) {

        let points1 = spielPunkte(list[i], 1);
        let points2 = spielPunkte(list[i], 2);

        gameArr.push({
            player1: points1, player2: points2,
            gesamt1: old1 + points1, gesamt2: old2 + points2
        });

        old1 += points1;
        old2 += points2;
    }

    return gameArr;
}

function spielPunkte(listEntry, team) {

    switch (team) {
        case 1:
            return (
                parseInt(listEntry.rein1) * 500 +
                parseInt(listEntry.unrein1) * 300 -
                parseInt(listEntry.hand1) +
                parseInt(listEntry.tisch1) +
                (parseInt(listEntry.dreier1) === 4 ? 800 : parseInt(listEntry.dreier1) * 100) +
                (listEntry.sonstiges1 === HANDKANASTER || listEntry.sonstiges1 === JOKERHANDKANSTER ? 1000 : 0) +
                (listEntry.sonstiges1 === JOKERKANASTER || listEntry.sonstiges1 === JOKERHANDKANSTER ? 1000 : 0)
            );
        case 2:
            return (
                parseInt(listEntry.rein2) * 500 +
                parseInt(listEntry.unrein2) * 300 -
                parseInt(listEntry.hand2) +
                parseInt(listEntry.tisch2) +
                (parseInt(listEntry.dreier2) === 4 ? 800 : parseInt(listEntry.dreier2) * 100) +
                (listEntry.sonstiges2 === HANDKANASTER || listEntry.sonstiges2 === JOKERHANDKANSTER ? 1000 : 0) +
                (listEntry.sonstiges2 === JOKERKANASTER || listEntry.sonstiges2 === JOKERHANDKANSTER ? 1000 : 0)
            );
        default:
            return "error";
    }
}
