import React from "react";
import "./styles.scss";
import LoginContainer from "../../Container/Login/LoginContainer";

class LoginMenu extends React.Component {

    state = {
        option: 1
    };

    render() {
        return (
            <div>
                <div className="main">
                    <LoginContainer/>
                </div>
            </div>
        );
    }

}

export default LoginMenu;