import React from "react";
import MMOldList from "./MMOldList";
import MMNewListContainer from "../../Container/Skat/MMNewListContainer";
import MenuNaviContainer from "../../Container/MenuNaviContainer";


class MainMenu extends React.Component {


    render() {
        return (
            <React.Fragment>
                <MenuNaviContainer/>

                <fieldset>
                    <MMNewListContainer/>
                </fieldset>
                <fieldset>
                    <MMOldList/>
                </fieldset>
            </React.Fragment>

        );
    }

}

export default MainMenu;