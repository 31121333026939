import React from "react";
import MenuNaviContainer from "../../Container/MenuNaviContainer";
import NewDokoListContainer from "../../Container/Doko/NewDokoListContainer";
import OldDokoList from "./OldDokoList";


class Doko extends React.Component {


    render() {
        return (
            <React.Fragment>
                <MenuNaviContainer/>

                <fieldset>
                    <NewDokoListContainer/>
                </fieldset>
                <fieldset>
                    <OldDokoList/>
                </fieldset>

            </React.Fragment>
        );
    }

}

export default Doko;

