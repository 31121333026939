import App from "../Components/App";
import {connect} from "react-redux";
import {finishEvent} from "../Actions";



const mapStateToProps = state => {
    return {
        menuFlag: state.PropertyReducer.menuFlag,
        eventFlag: state.PropertyReducer.eventFlag,
        eventMsg: state.PropertyReducer.eventMsg
    }
};

const mapDispatchToProps = dispatch => {
    return {
        finishEvent: () => dispatch(finishEvent())
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(App);