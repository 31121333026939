import React from "react";

class OldDokoListItems extends React.Component {

    componentDidMount() {
        this.props.retrieveOldDokoIds(this.props.accountName);
    }

    render() {
        return (
            <React.Fragment>
                <div>
                    {this.props.dokoListIds.map((oldList, index) => {
                        return (
                            <div key={"dokoList_" + index}
                                 style={{
                                     'border': '1px solid black',
                                     'backgroundColor': '#666',
                                     'display': 'flex',
                                     'flexWrap': 'wrap',
                                     'justifyContent': 'space-evenly',
                                 }}
                            >
                                <div>{oldList.gameDate}</div>
                                <div>{oldList.player1}</div>
                                <div>{oldList.player2}</div>
                                <div>{oldList.player3}</div>
                                <div>{oldList.player4}</div>
                                {oldList.playerCount === "4" ? <div/> : <div>{oldList.player5}</div>}
                                <div>
                                    <button
                                        style={{'backgroundColor': '#AAAAAA'}}
                                        className="pure-button"
                                        type="button"
                                        onClick={() => {
                                            this.props.retrieveDokoList(oldList);
                                        }}
                                    >
                                        <i className="fas fa-upload"/></button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </React.Fragment>
        );
    }

}

export default OldDokoListItems;