import {connect} from "react-redux";
import KanaTable from "../../Components/Kana/KanaTable";


const mapStateToProps = state => {
    return {
        addGameFlag: state.PropertyReducer.addGameFlag,
    }
};

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps,mapDispatchToProps)(KanaTable);