import {connect} from "react-redux";
import OldLists from "../../Components/Skat/OldLists";
import {retrieveList, retrieveOldListIds} from "../../Actions";


const mapStateToProps = state => {
    return {
        accountName: state.PropertyReducer.accountName,
        oldLists: state.PropertyReducer.oldLists,
    }
};

const mapDispatchToProps = dispatch => ({
    retrieveOldListIds: (accountName) => { dispatch(retrieveOldListIds(accountName))},
    retrieveList: (listId) => { dispatch(retrieveList(listId))},
});

export default connect(mapStateToProps,mapDispatchToProps)(OldLists);