import React from "react";

export const KEINE_ANSAGE = 0, HAND_ANSAGE = 1, SCHNEIDER_ANSAGE = 2, SCHWARZ_ANSAGE = 3, OUVERT_ANSAGE = 4,
    HAND_OUVERT_ANSAGE = 5, REVOLUTION = 6, JUNGFRAU = 7;
export const EINFACH_PUNKTE = 1, ZWEIFACH_PUNKTE = 2, VIERFACH_PUNKTE = 4, ACHTFACH_PUNKTE = 8, SECHZEHNFACH_PUNKTE = 16;

class GameTableBody extends React.Component {

    checkFourthPlayer = (gameMaker, winner, punktePlayer, index) => {
        if (this.props.playerCount === 'fourPlayer') {
            return (
                <React.Fragment>
                    <td style={this.isGeber(index, 3) ? {backgroundColor: '#E3D2B8'} : {}}>{gameMaker === this.props.player4 ? punktePlayer[0] : "---"}</td>
                    <td>{gameMaker === this.props.player4 && winner ? punktePlayer[1] : ""}</td>
                    <td className="borderRight">{gameMaker === this.props.player4 && !winner ? punktePlayer[2] : ""}</td>
                </React.Fragment>
            );
        }

    };

    parseSpielwert = (spielwert) => {
        switch (spielwert.toLowerCase()) {
            case "karo": return 9;
            case "herz": return 10;
            case "pik":  return 11;
            case "kreuz": return 12;
            case "null":  return 23;
            case "ramsch": return "R";
            case "grand": return 24;
            default: return 0;
        }
    };
    parseSpielwertToImg = (spielwert) => {
        switch (spielwert.toLowerCase()) {
            case "karo":
                return <img
                    src="./images/Symbole/karo.png"
                    className="grundwertSymbol"
                    alt="9"
                />;
            case "herz":
                return <img
                    src="./images/Symbole/herz.png"
                    className="grundwertSymbol"
                    alt="10"
                />;
            case "pik":
                return <img
                    src="./images/Symbole/pik.png"
                    className="grundwertSymbol"
                    alt="11"
                />;
            case "kreuz":
                return <img
                    src="./images/Symbole/kreuz.png"
                    className="grundwertSymbol"
                    alt="12"
                />;
            case "null":
                return <img
                    src="./images/Symbole/null.png"
                    className="grundwertSymbol"
                    alt="23"
                />;
            case "ramsch":
                return <img
                    src="./images/Symbole/ramsch.png"
                    className="grundwertSymbol"
                    alt="R"
                />;
            case "grand":
                return <img
                    src="./images/Symbole/grand.png"
                    className="grundwertSymbol"
                    alt="24"
                />;
            default:
                return 0;
        }
    };
    parseAnsagen = (ansage) => {
        switch (ansage.toLowerCase()) {
            case "keine": return KEINE_ANSAGE;
            case "hand": return HAND_ANSAGE;
            case "schneider ange.": return SCHNEIDER_ANSAGE;
            case "schwarz ange.": return SCHWARZ_ANSAGE;
            case "ouvert": return OUVERT_ANSAGE;
            case "handouvert": return HAND_OUVERT_ANSAGE;
            case "revolution": return REVOLUTION;
            case "jungfrau": return JUNGFRAU;
            default: return KEINE_ANSAGE;
        }
    };
    parseKontra = (kontra) => {
        switch (kontra.toLowerCase()) {
            case "nein":
                return EINFACH_PUNKTE;
            case "kontra":
                return ZWEIFACH_PUNKTE;
            case "re":
                return VIERFACH_PUNKTE;
            case "sup":
                return ACHTFACH_PUNKTE;
            case "hirsch":
                return SECHZEHNFACH_PUNKTE;
            default:
                return EINFACH_PUNKTE
        }
    };
    parseGameMaker = (gameMaker) => {
        switch (gameMaker) {
            case this.props.player1:
                return 0;
            case this.props.player2:
                return 1;
            case this.props.player3:
                return 2;
            case this.props.player4:
                return 3;
            default:
                return 0;
        }
    };

    calculateWinner = (ansage, punkte, spielwert) => {
        ansage = this.parseAnsagen(ansage);
        spielwert = this.parseSpielwert(spielwert);
        if (spielwert === 23) {
            return (Number(punkte) === 0);
        }
        if (spielwert === "R") {
            return false;
        }
        switch (ansage) {
            case SCHNEIDER_ANSAGE:
                return (punkte > 89);
            case SCHWARZ_ANSAGE:
                return (punkte > 119);
            default:
                return (punkte > 60);
        }
    };
    spielwertBerechnen = (ansage, kontra, buben, punkte, spielwert) => {
        ansage = this.parseAnsagen(ansage);
        spielwert = this.parseSpielwert(spielwert);
        kontra = this.parseKontra(kontra);
        if (spielwert === 23) {
            switch (ansage) {
                case REVOLUTION:
                    return 92 * kontra;
                case HAND_OUVERT_ANSAGE:
                    return 59 * kontra;
                case OUVERT_ANSAGE:
                    return 46 * kontra;
                case HAND_ANSAGE:
                    return 35 * kontra;
                default:
                    return 23 * kontra;
            }
        }
        if (spielwert === "R") {
            if (ansage === JUNGFRAU) {
                return (punkte)
            }
            return punkte / 2;
        }

        if (punkte > 89 || punkte < 31) {
            buben = buben + 1;
        }
        if (punkte > 119 || punkte < 1) {
            buben = buben + 1;
        }
        return ((buben + 1 + (ansage < HAND_OUVERT_ANSAGE ? ansage : 0)) * spielwert * kontra);
    };
    checkNullOrRamsch = (spielwert) => {
        spielwert = this.parseSpielwert(spielwert);
        return (spielwert === 23 || spielwert === "R");
    };
    isGeber = (spiel, spieler) => {
        if (this.props.playerCount === 'threePlayer') {
            return (spiel % 3 === spieler);
        }
        return (spiel % 4 === spieler);
    };

    renderTableMiddle = (punktePlayer) => {
        return (
            (this.props.gameList || []).map((listEntry, index) => {
                const winner = this.calculateWinner(listEntry.ansagen, listEntry.punkte, listEntry.spielwert);
                const endpunktzahl = this.spielwertBerechnen(listEntry.ansagen, listEntry.kontra, listEntry.buben, listEntry.punkte, listEntry.spielwert);
                if (winner) {
                    punktePlayer[this.parseGameMaker(listEntry.gameMaker)][0] += endpunktzahl;
                    punktePlayer[this.parseGameMaker(listEntry.gameMaker)][1] += 1;
                } else {
                    punktePlayer[this.parseGameMaker(listEntry.gameMaker)][0] -= (endpunktzahl * 2);
                    punktePlayer[this.parseGameMaker(listEntry.gameMaker)][2] += 1;
                }
                return (
                    <tr
                        key={"gameTableBody_" + index}
                        className={this.props.playerCount === "threePlayer" ? "skatTableMiddleThree" : "skatTableMiddleFour"}
                    >
                        <td>
                            {listEntry.gameNumber}&nbsp;
                            {listEntry.gameNumber === this.props.gameList.length && this.props.addGameFlag === false
                                ? <i
                                    id="littleIcon"
                                    className="fas fa-trash-alt"
                                    onClick={() => {
                                        if(window.confirm("Letzten Eintrag löschen?")) {
                                        this.props.deleteListEntry(this.props.listId, listEntry.gameNumber)}
                                    }}
                                    style={{fontSize: '10px', cursor:'pointer'}}/>
                                : <div/>
                            }
                        </td>
                        <td>
                            {this.parseSpielwertToImg(listEntry.spielwert)}
                        </td>
                        {this.checkNullOrRamsch(listEntry.spielwert) ?
                            <td colSpan="2"
                                className="borderRight">{this.parseAnsagen(listEntry.ansagen) === JUNGFRAU ? "JF" : ""}</td> :
                            <React.Fragment>
                                <td>{listEntry.mitOhne === "mit" ? listEntry.buben : ""}</td>
                                <td className="borderRight">{listEntry.mitOhne === "ohne" ? listEntry.buben : ""}</td>
                            </React.Fragment>}
                        <td>
                            {this.parseAnsagen(listEntry.ansagen) === HAND_ANSAGE ||
                            (this.parseAnsagen(listEntry.ansagen) === HAND_OUVERT_ANSAGE && listEntry.spielwert === 'null') ? "x" : ""}
                        </td>
                        <td>{(listEntry.punkte > 89 || listEntry.punkte < 31) && !this.checkNullOrRamsch(listEntry.spielwert) ? "x" : ""}</td>
                        <td>{this.parseAnsagen(listEntry.ansagen) === SCHNEIDER_ANSAGE ? "x" : " "}</td>
                        <td>{(listEntry.punkte > 119 || listEntry.punkte < 1) && !this.checkNullOrRamsch(listEntry.spielwert) ? "x" : ""}</td>
                        <td>{this.parseAnsagen(listEntry.ansagen) === SCHWARZ_ANSAGE ? "x" : ""}</td>
                        <td>
                            {this.parseAnsagen(listEntry.ansagen) === OUVERT_ANSAGE ||
                            (this.parseAnsagen(listEntry.ansagen) === HAND_OUVERT_ANSAGE && listEntry.spielwert === 'null') ? "x" : ""}
                        </td>
                        <td className="borderRight">{listEntry.kontra === "nein" ? "" : "x" + this.parseKontra(listEntry.kontra)}</td>
                        <td>{winner ? endpunktzahl : ""}</td>
                        <td style={{
                            borderRight: 'solid 2px #BB9966',
                            color: 'darkred'
                        }}>{winner ? " " : endpunktzahl * 2}</td>
                        <td style={this.isGeber(listEntry.gameNumber - 1, 0) ? {backgroundColor: '#E3D2B8'} : {}}>{listEntry.gameMaker === this.props.player1 ? punktePlayer[0][0] : "---"}</td>
                        <td>{listEntry.gameMaker === this.props.player1 && winner ? punktePlayer[0][1] : ""}</td>
                        <td className="borderRight">{listEntry.gameMaker === this.props.player1 && !winner ? punktePlayer[0][2] : ""}</td>
                        <td style={this.isGeber(listEntry.gameNumber - 1, 1) ? {backgroundColor: '#E3D2B8'} : {}}>{listEntry.gameMaker === this.props.player2 ? punktePlayer[1][0] : "---"}</td>
                        <td>{listEntry.gameMaker === this.props.player2 && winner ? punktePlayer[1][1] : ""}</td>
                        <td className="borderRight">{listEntry.gameMaker === this.props.player2 && !winner ? punktePlayer[1][2] : ""}</td>
                        <td style={this.isGeber(listEntry.gameNumber - 1, 2) ? {backgroundColor: '#E3D2B8'} : {}}>{listEntry.gameMaker === this.props.player3 ? punktePlayer[2][0] : "---"}</td>
                        <td>{listEntry.gameMaker === this.props.player3 && winner ? punktePlayer[2][1] : ""}</td>
                        <td className="borderRight">{listEntry.gameMaker === this.props.player3 && !winner ? punktePlayer[2][2] : ""}</td>
                        {this.checkFourthPlayer(listEntry.gameMaker, winner, punktePlayer[3], listEntry.gameNumber - 1)}
                    </tr>
                );
            })
        );
    };

    getPointsForEachWin = (player, punktePlayer) => {
        return ((punktePlayer[player][1] - punktePlayer[player][2]) * 50);
    };
    getPointsForEnemyLoose = (player, punktePlayer) => {
        let sum = 0;
        for (let i = 0; i < 4; i++) {
            if (i !== player) {
                sum += punktePlayer[i][2];
            }
        }
        return (
            (sum * (this.props.playerCount === 'fourPlayer' ? 30 : 40))
        );
    };
    getTotalPoints = (player, punktePlayer) => {
        return (
            punktePlayer[player][0] + this.getPointsForEachWin(player, punktePlayer) + this.getPointsForEnemyLoose(player, punktePlayer)
        );
    };
    getTotalPosition = (player, punktePlayer) => {
        let myRank = 1;
        let myPoints = this.getTotalPoints(player, punktePlayer);
        for (let i = 0; i < (this.props.playerCount === 'fourPlayer' ? 4 : 3); i++) {
            if (player !== i) {
                if (myPoints < this.getTotalPoints(i, punktePlayer)) {
                    myRank += 1;
                }
            }
        }
        return myRank;
    };

    renderTableFooter = (punktePlayer) => {
        return (
            <React.Fragment>
                <tr className="borderTop">
                    <td colSpan="13" className="borderRight rightFlushed">Ergebnis, Punkte und Spiele</td>
                    <td>{punktePlayer[0][0]}</td>
                    <td>{punktePlayer[0][1]}</td>
                    <td className="borderRight">{punktePlayer[0][2]}</td>
                    <td>{punktePlayer[1][0]}</td>
                    <td>{punktePlayer[1][1]}</td>
                    <td className="borderRight">{punktePlayer[1][2]}</td>
                    <td>{punktePlayer[2][0]}</td>
                    <td>{punktePlayer[2][1]}</td>
                    <td className="borderRight">{punktePlayer[2][2]}</td>
                    {this.props.playerCount === 'fourPlayer' ? <td>{punktePlayer[3][0]}</td> : <React.Fragment/>}
                    {this.props.playerCount === 'fourPlayer' ? <td>{punktePlayer[3][1]}</td> : <React.Fragment/>}
                    {this.props.playerCount === 'fourPlayer' ? <td className="borderRight">{punktePlayer[3][2]}</td> :
                        <React.Fragment/>}
                </tr>
                <tr>
                    <td colSpan="13" className="rightFlushed borderRight">(gew.- verl. Spiele) * 50 Pkt</td>
                    <td colSpan="3" className="borderRight">{this.getPointsForEachWin(0, punktePlayer)}</td>
                    <td colSpan="3" className="borderRight">{this.getPointsForEachWin(1, punktePlayer)}</td>
                    <td colSpan="3" className="borderRight">{this.getPointsForEachWin(2, punktePlayer)}</td>
                    {this.props.playerCount === 'fourPlayer' ?
                        <td colSpan="3" className="borderRight">{this.getPointsForEachWin(3, punktePlayer)}</td> :
                        <React.Fragment/>}
                </tr>
                <tr>
                    <td colSpan="13" className="borderRight rightFlushed">(gegn. Verlustspiele)
                        * {this.props.playerCount === 'fourPlayer' ? '30 ' : '40 '}Pkt
                    </td>
                    <td colSpan="3" className="borderRight">{this.getPointsForEnemyLoose(0, punktePlayer)}</td>
                    <td colSpan="3" className="borderRight">{this.getPointsForEnemyLoose(1, punktePlayer)}</td>
                    <td colSpan="3" className="borderRight">{this.getPointsForEnemyLoose(2, punktePlayer)}</td>
                    {this.props.playerCount === 'fourPlayer' ?
                        <td colSpan="3" className="borderRight">{this.getPointsForEnemyLoose(3, punktePlayer)}</td> :
                        <React.Fragment/>}
                </tr>
                <tr>
                    <td colSpan="13" rowSpan="2" className="borderRight rightFlushed">Endergebnis</td>
                    <td colSpan="3" className="borderRight">{this.getTotalPoints(0, punktePlayer)}</td>
                    <td colSpan="3" className="borderRight">{this.getTotalPoints(1, punktePlayer)}</td>
                    <td colSpan="3" className="borderRight">{this.getTotalPoints(2, punktePlayer)}</td>
                    {this.props.playerCount === 'fourPlayer' ?
                        <td colSpan="3" className="borderRight">{this.getTotalPoints(3, punktePlayer)}</td> :
                        <React.Fragment/>}
                </tr>
                <tr>
                    <td colSpan="3" className="borderRight">{this.getTotalPosition(0, punktePlayer) + '. Platz'}</td>
                    <td colSpan="3" className="borderRight">{this.getTotalPosition(1, punktePlayer) + '. Platz'}</td>
                    <td colSpan="3" className="borderRight">{this.getTotalPosition(2, punktePlayer) + '. Platz'}</td>
                    {this.props.playerCount === 'fourPlayer' ?
                        <td colSpan="3"
                            className="borderRight">{this.getTotalPosition(3, punktePlayer) + '. Platz'}</td> :
                        <React.Fragment/>}
                </tr>
            </React.Fragment>
        );
    };

    render() {
        const punktePlayer = [[0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0]];
        return (
            <React.Fragment>
                {this.renderTableMiddle(punktePlayer)}
                {this.renderTableFooter(punktePlayer)}
            </React.Fragment>
        );
    }

}

export default GameTableBody;

