import {LOAD_STATISTIK} from "../Actions";

const initialState = {
    statistik: {},
};

const statistikReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_STATISTIK:
            return Object.assign({}, state, {
                statistik: action.statistik,
            });
        default:
            return state;
    }
};

export default statistikReducer;