import React from "react";
import {CHOOSE_DOKO, CHOOSE_KANA, CHOOSE_LIST} from "../Actions";
import "./MenuNavi.scss";

class MenuNavi extends React.Component {

    render() {
        return (
            <nav className="navbar navbar-expand navbar-dark bg-dark py-1">
                <div className="container-fluid">
                    <a className="navbar-brand mr-5" href="/#">Skat.Parpart.nl</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarColor02" aria-controls="navbarColor02" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarColor02">
                        <ul className="navbar-nav me-auto">
                            <li className="nav-item">
                                <a className={"nav-link " + (this.props.menuFlag === CHOOSE_LIST ? "active" : "")} href="/#"
                                   onClick={() => {
                                       this.props.setMenuFlag(CHOOSE_LIST)
                                   }}>
                                    Skat
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className={"nav-link " + (this.props.menuFlag === CHOOSE_DOKO ? "active" : "")}
                                   href="/#" onClick={() => {
                                    this.props.setMenuFlag(CHOOSE_DOKO)
                                }}>
                                    DoKo
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className={"nav-link " + (this.props.menuFlag === CHOOSE_KANA ? "active" : "")}
                                   href="/#" onClick={() => {
                                    this.props.setMenuFlag(CHOOSE_KANA)
                                }}>
                                    Knaster
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" target="_blank"
                                   href="https://statistik.parpart.nl" rel="noreferrer">Statistik
                                </a>
                            </li>
                        </ul>
                        <form className="d-flex">
                            <button className="btn btn-secondary my-2 my-sm-0" type="button"
                                    onClick={() => {
                                        this.props.logMeOut()
                                    }}>
                                Logout
                            </button>
                        </form>
                    </div>
                </div>
            </nav>
        );
    }

}

export default MenuNavi;