import Login from "../../Components/Login/Login";
import {connect} from "react-redux";
import {postLogin, setLogin} from "../../Actions";



const mapStateToProps = state => {
  return {   }
};

const mapDispatchToProps = dispatch => ({
            postLogin: data => {dispatch(postLogin(data))},
            setLogin: data => {dispatch(setLogin(data))},
    });

export default connect(mapStateToProps,mapDispatchToProps)(Login);