import React from "react";
import GameTableBodyContainer from "../../Container/Skat/GameTableBodyContainer";

const VERTICAL_TEXT = 'verticalText';
const RENDER_SITZ4 = 1, RENDER_GV = 3, RENDER_NAME = 2;

class GameTable extends React.Component {


    checkFourthPlayer = (elementToRender) => {
        if (this.props.playerCount === 'fourPlayer' && elementToRender === RENDER_SITZ4) {
            return (<th colSpan="3"/>);
        }
        if (this.props.playerCount === 'fourPlayer' && elementToRender === RENDER_GV) {
            return (
                <React.Fragment>
                    <th/>
                    <th>
                        <img
                            className={VERTICAL_TEXT}
                            src="./images/vertikaleSchrift/gew.png"
                            alt="gew"
                        />
                    </th>
                    <th className="borderRight">
                        <img
                            className={VERTICAL_TEXT}
                            src="./images/vertikaleSchrift/ver.png"
                            alt="verl"
                        />
                    </th>
                </React.Fragment>
            )
        }
        if (this.props.playerCount === 'fourPlayer' && elementToRender === RENDER_NAME) {
            return (
                <th className="centered borderRight" colSpan="3">{this.props.player4}</th>
            );
        }
    };

    render() {
        return (
            <React.Fragment>
                <table className="skatTable" style={{border: 'solid 2px #BB9966'}}>
                    <thead>
                    <tr>
                        <th rowSpan="3" style={{maxWidth: "30px"}}>
                            <button
                                style={{height:'65px', width:'80%', maxWidth: "60px"}}
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {
                                    this.props.changeGameFlag(!this.props.addGameFlag)
                                }}
                            >
                                <i className="far fa-plus-square"
                                   style={{fontSize: "20px"}}/>
                            </button>
                        </th>
                        <th rowSpan="3">
                            <img
                                className={VERTICAL_TEXT}
                                src="./images/vertikaleSchrift/Grundwerte.png"
                                alt="Grundwerte"
                            />
                        </th>
                        <th rowSpan="2" colSpan="2" className="borderRight" style={{borderBottom: 'hidden'}}>
                            <img
                                className={VERTICAL_TEXT}
                                src="./images/vertikaleSchrift/Spitzen.png"
                                alt="Spitzen"
                            />
                        </th>
                        <th colSpan="7" className="borderRight">Gewinnstufen</th>
                        <th colSpan="2" rowSpan="2" className="borderRight">Spielwert</th>
                        <th colSpan="9" className="centered">{"Datum: " + (new Date(this.props.gameDate)).toLocaleDateString()}</th>
                        {this.checkFourthPlayer(RENDER_SITZ4)}
                    </tr>
                    <tr>
                        <th rowSpan="2">
                            <img
                                className={VERTICAL_TEXT}
                                src="./images/vertikaleSchrift/Hand.png"
                                alt="Hand"
                            />
                        </th>
                        <th rowSpan="2">
                            <img
                                className={VERTICAL_TEXT}
                                src="./images/vertikaleSchrift/Schneider.png"
                                alt="Schneider"
                            />
                        </th>
                        <th rowSpan="2">
                            <img
                                className={VERTICAL_TEXT}
                                src="./images/vertikaleSchrift/angesagt.png"
                                alt="angesagt"
                            />
                        </th>
                        <th rowSpan="2">
                            <img
                                className={VERTICAL_TEXT}
                                src="./images/vertikaleSchrift/Schwarz.png"
                                alt="Schwarz"
                            />
                        </th>
                        <th rowSpan="2">
                            <img
                                className={VERTICAL_TEXT}
                                src="./images/vertikaleSchrift/angesagt.png"
                                alt="angesagt"
                            />
                        </th>
                        <th rowSpan="2">
                            <img
                                className={VERTICAL_TEXT}
                                src="./images/vertikaleSchrift/offen.png"
                                alt="offen"
                            />
                        </th>
                        <th rowSpan="2" className="borderRight">
                            <img
                                className={VERTICAL_TEXT}
                                src="./images/vertikaleSchrift/Kontra.png"
                                alt="Kontra"
                            />
                        </th>
                        <th className="centered borderRight" colSpan="3" >{this.props.player1}</th>
                        <th className="centered borderRight" colSpan="3">{this.props.player2}</th>
                        <th className="centered borderRight" colSpan="3">{this.props.player3}</th>
                        {this.checkFourthPlayer(RENDER_NAME)}
                    </tr>
                    <tr style={{borderBottom: 'solid 2px #BB9966'}}>
                        <th>
                            <img
                                className={VERTICAL_TEXT}
                                src="./images/vertikaleSchrift/mit.png"
                                alt="mit"
                            />
                        </th>
                        <th className="borderRight">
                            <img
                                className={VERTICAL_TEXT}
                                src="./images/vertikaleSchrift/ohne.png"
                                alt="ohne"
                            />
                        </th>
                        <th>+</th>
                        <th className="borderRight">-</th>
                        <th/>
                        <th>
                            <img
                                className={VERTICAL_TEXT}
                                src="./images/vertikaleSchrift/gew.png"
                                alt="gew"
                            />
                        </th>
                        <th className="borderRight">
                            <img
                                className={VERTICAL_TEXT}
                                src="./images/vertikaleSchrift/ver.png"
                                alt="verl"
                            />
                        </th>
                        <th/>
                        <th>
                            <img
                                className={VERTICAL_TEXT}
                                src="./images/vertikaleSchrift/gew.png"
                                alt="gew"
                            />
                        </th>
                        <th className="borderRight">
                            <img
                                className={VERTICAL_TEXT}
                                src="./images/vertikaleSchrift/ver.png"
                                alt="verl"
                            />
                        </th>
                        <th/>
                        <th>
                            <img
                                className={VERTICAL_TEXT}
                                src="./images/vertikaleSchrift/gew.png"
                                alt="gew"
                            />
                        </th>
                        <th className="borderRight">
                            <img
                                className={VERTICAL_TEXT}
                                src="./images/vertikaleSchrift/ver.png"
                                alt="verl"
                            />
                        </th>
                        {this.checkFourthPlayer(RENDER_GV)}
                    </tr>
                    </thead>
                    <tbody>
                    <GameTableBodyContainer/>
                    </tbody>
                </table>

            </React.Fragment>
        );
    }

}

export default GameTable;

