import {connect} from "react-redux";
import GameTableBody from "../../Components/Skat/GameTableBody";
import {deleteListEntry} from "../../Actions";



const mapStateToProps = state => {
    return {
        addGameFlag: state.PropertyReducer.addGameFlag,
        listId: state.PropertyReducer.listId,
        gameList: state.ListReducer.gameList,
        gameDate: state.PropertyReducer.gameDate,
        playerCount: state.PropertyReducer.playerCount,
        player1: state.PropertyReducer.player1,
        player2: state.PropertyReducer.player2,
        player3: state.PropertyReducer.player3,
        player4: state.PropertyReducer.player4
    }
};

const mapDispatchToProps = dispatch => ({
    deleteListEntry: (listId, gameNumber) => {dispatch(deleteListEntry(listId, gameNumber))}
});

export default connect(mapStateToProps,mapDispatchToProps)(GameTableBody);