import React from "react";
import OldDokoListItemsContainer from "../../Container/Doko/OldDokoListItemsContainer";


class OldDokoList extends React.Component {

    state = {
        oldListsFlag: false,
    };

    render() {
        return (
            <form className="mt-5">
                <fieldset>
                        <h3>Alte Listen fortsetzen</h3>
                    <div className="pure-u-1">
                        <button
                            className="btn btn-secondary"
                            type="button"
                            onClick={() => {
                                this.setState({oldListsFlag: !this.state.oldListsFlag})
                            }}
                        >
                            {this.state.oldListsFlag ? <i className="fas fa-chevron-up"/> :  <i className="fas fa-chevron-down"/>}
                            {this.state.oldListsFlag ? '  Alte Listen ausblenden' : '  Alte Listen anzeigen'}
                        </button>

                        {this.state.oldListsFlag ? <OldDokoListItemsContainer/> : ''}
                    </div>
                </fieldset>
            </form>

        );
    }

}

export default OldDokoList;