import React from "react";
import GameTableContainer from "../../Container/Skat/GameTableContainer";
import GameAdderContainer from "../../Container/Skat/GameAdderContainer";
import MenuNaviContainer from "../../Container/MenuNaviContainer";


class Game extends React.Component {


    render() {
        return (
            <React.Fragment>
                <MenuNaviContainer/>
                {this.props.addGameFlag ? <GameAdderContainer/> : <div/>}
                <GameTableContainer/>
            </React.Fragment>
        );
    }

}

export default Game;

