import React from "react";
import {getCookie} from "../../Actions";

class Login extends React.Component {
    state = {
        name: "",
        pass: "",
    };

    componentDidMount() {
        const jwt = getCookie('jwt');
        const username = getCookie('username');
        if (jwt !== "" && jwt !== "undefined") {
            this.props.setLogin({
                jwt: jwt,
                username: username,
            })
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.postLogin({
            username: this.state.name,
            password: this.state.pass
        });

    }

    render() {
        return (
            <div className="d-flex justify-content-center h-100">
                <div className="card">
                    <div className="card-header">
                        <h3>Sign In</h3>
                        <div className="d-flex justify-content-end social_icon">
                            <a href={"https://www.linkedin.com/company/btcleak"}>
                            <span>
                                <i className="fab fa-linkedin"></i>
                            </span>
                            </a>
                            <a href={"https://www.reddit.com/user/btcleaksCom"}>
                                <span><i className="fab fa-reddit-square"></i></span>
                            </a>
                            <a href={"https://twitter.com/btcleaks"}>
                                <span><i className="fab fa-twitter-square"></i></span>
                            </a>
                        </div>
                    </div>
                    <div className="card-body">
                        <form onSubmit={e => this.handleSubmit(e)}>
                            <div className="input-group form-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><i className="fas fa-user"></i></span>
                                </div>
                                <input type="text" className="form-control" placeholder="username"
                                       onChange={(e) => this.setState({name: e.target.value})}/>

                            </div>
                            <div className="input-group form-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><i className="fas fa-key"></i></span>
                                </div>
                                <input type="password" className="form-control" placeholder="password"
                                       onChange={(e) => this.setState({pass: e.target.value})}/>
                            </div>
                            <div className="form-group">
                                <input type="submit" value="Login" className="btn float-right login_btn"/>
                            </div>
                        </form>
                    </div>
                    <div className="card-footer">
                        <div className="d-flex justify-content-center links">
                            Don't have an account?<a href="/#">Sign Up</a>
                        </div>
                        <div className="d-flex justify-content-center">
                            <a href="/#">Forgot your password?</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default Login;