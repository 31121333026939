import {connect} from "react-redux";
import {addGameFlag} from "../../Actions";
import KanaTableHeader from "../../Components/Kana/KanaTableHeader";


const mapStateToProps = state => {
    return {
        addGameFlag: state.PropertyReducer.addGameFlag,
        player1: state.PropertyReducer.player1,
        player2: state.PropertyReducer.player2,
        player3: state.PropertyReducer.player3,
        player4: state.PropertyReducer.player4,
        player5: state.PropertyReducer.player5,
        player6: "",
        playerCount: state.PropertyReducer.playerCount,
        gameDate: state.PropertyReducer.gameDate,
    }
};

const mapDispatchToProps = dispatch => ({
    changeGameFlag: (flag) => dispatch(addGameFlag(flag)),
});

export default connect(mapStateToProps,mapDispatchToProps)(KanaTableHeader);