import {connect} from "react-redux";
import StatistikContent from "../../Components/Statistik/StatistikContent";

const mapStateToProps = state => {
    return {
        statistik: state.StatistikReducer.statistik,
    }
};

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps,mapDispatchToProps)(StatistikContent);