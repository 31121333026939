import React from "react";
import {KANA_IN_PROGRESS} from "../../Actions";


class NewKanaList extends React.Component {

    state = {
        accountName: this.props.accountName,
        playerCount: '4',
        player1: '',
        player2: '',
        player3: '',
        player4: '',
        player5: '',
        player6: '',
        menuFlag: KANA_IN_PROGRESS,
        rules: 3000,
        game: 'Kana',
    };

    handleRadioChange = (e) => {
        this.setState({
            playerCount: e.target.value
        });
    };

    handleSubmit(e) {
        e.preventDefault();
        this.props.retrieveListId(this.state);
    }

    render() {
        return (
            <form className="mt-4"
                  onSubmit={e => this.handleSubmit(e)}
            >
                <fieldset>
                    <h2>Neue Kanaster-Liste starten</h2>
                    <div className="form-check form-check-inline">
                        <input id="fourPlayer" type="radio" name="playerRadio" value='4'
                               checked={this.state.playerCount === '4'}
                               onChange={this.handleRadioChange} className={"form-check-input"}
                        />
                        <label htmlFor="fourPlayer" className="form-check-label">
                            &nbsp;4 Spieler
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input id="sixPlayer" type="radio" name="playerRadio" value='6' disabled
                               checked={this.state.playerCount === '6'}
                               onChange={this.handleRadioChange} className={"form-check-input"}
                        />
                        <label htmlFor="sixPlayer" className="form-check-label">
                            &nbsp;6 Spieler
                        </label>
                    </div>
                    <div className="form-group col-md-4 col-sm-12">
                        <input
                            required
                            className={"form-control"}
                            type="text"
                            placeholder="Spieler 1"
                            value={this.state.player1}
                            onChange={(e) => this.setState({player1: e.target.value})}
                        />
                    </div>
                    <div className="form-group col-md-4 col-sm-12">
                        <input
                            required
                            className={"form-control"}
                            type="text"
                            placeholder="Spieler 2"
                            value={this.state.player2}
                            onChange={(e) => this.setState({player2: e.target.value})}
                        />
                    </div>
                    <div className="form-group col-md-4 col-sm-12">
                        <input
                            required
                            className={"form-control"}
                            type="text"
                            placeholder="Spieler 3"
                            value={this.state.player3}
                            onChange={(e) => this.setState({player3: e.target.value})}
                        />
                    </div>
                    <div className="form-group col-md-4 col-sm-12">
                        <input
                            required
                            className={"form-control"}
                            type="text"
                            placeholder="Spieler 4"
                            value={this.state.player4}
                            onChange={(e) => this.setState({player4: e.target.value})}
                        />
                    </div>
                    <div className={this.state.playerCount === '6' ? 'form-group col-md-4 col-sm-12' : 'hidden'}>
                        <input
                            required={this.state.playerCount === '6'}
                            type="text"
                            className={"form-control"}
                            placeholder="Spieler 5"
                            value={this.state.player5}
                            onChange={(e) => this.setState({player5: e.target.value})}
                        />
                    </div>
                    <div className={this.state.playerCount === '6' ? 'form-group col-md-4 col-sm-12' : 'hidden'}>
                        <input
                            required={this.state.playerCount === '6'}
                            type="text"
                            className={"form-control"}
                            placeholder="Spieler 6"
                            value={this.state.player6}
                            onChange={(e) => this.setState({player5: e.target.value})}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <button
                            className="btn btn-primary"
                            type="submit"
                        >
                            <i className="fas fa-play"/>
                            &nbsp; Start &nbsp;
                            <i className="fas fa-play"/>
                        </button>
                    </div>
                </fieldset>
            </form>
        );
    }

}

export default NewKanaList;