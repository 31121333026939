import React from "react";
import {DOKO_IN_PROGRESS} from "../../Actions";


class NewDokoList extends React.Component {

    state = {
        accountName: this.props.accountName,
        playerCount: '4',
        player1: '',
        player2: '',
        player3: '',
        player4: '',
        player5: '',
        menuFlag: DOKO_IN_PROGRESS,
        rules: 2000,
        game: 'Doko',
    };

    handleRadioChange = (e) => {
        this.setState({
            playerCount: e.target.value
        });
    };

    handleSubmit(e) {
        e.preventDefault();
        this.props.retrieveListId(this.state);
    }

    render() {
        return (
            <form className="mt-4"
                  onSubmit={e => this.handleSubmit(e)}
            >
                <fieldset>
                    <h2>Neue Doko-Liste starten</h2>
                    <div className="form-check form-check-inline">
                        <input id="fourPlayer" type="radio" name="playerRadio" value='4'
                               checked={this.state.playerCount === '4'}
                               onChange={this.handleRadioChange} className={"form-check-input"}/>
                        <label htmlFor="fourPlayer" className="form-check-label">
                            &nbsp;4 Spieler
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input id="fivePlayer" type="radio" name="playerRadio" value='5'
                               checked={this.state.playerCount === '5'}
                               onChange={this.handleRadioChange} className={"form-check-input"}/>
                        <label htmlFor="fivePlayer" className="form-check-label">
                            &nbsp;5 Spieler
                        </label>
                    </div>
                    <div className="form-group col-md-4 col-sm-12">
                        <input
                            required
                            className={"form-control"}
                            type="text"
                            placeholder="Spieler 1"
                            value={this.state.player1}
                            onChange={(e) => this.setState({player1: e.target.value})}
                        />
                    </div>
                    <div className="form-group col-md-4 col-sm-12">
                        <input
                            required
                            className={"form-control"}
                            type="text"
                            placeholder="Spieler 2"
                            value={this.state.player2}
                            onChange={(e) => this.setState({player2: e.target.value})}
                        />
                    </div>
                    <div className="form-group col-md-4 col-sm-12">
                        <input
                            required
                            className={"form-control"}
                            type="text"
                            placeholder="Spieler 3"
                            value={this.state.player3}
                            onChange={(e) => this.setState({player3: e.target.value})}
                        />
                    </div>
                    <div className="form-group col-md-4 col-sm-12">
                        <input
                            required
                            className={"form-control"}
                            type="text"
                            placeholder="Spieler 4"
                            value={this.state.player4}
                            onChange={(e) => this.setState({player4: e.target.value})}
                        />
                    </div>
                    <div className={this.state.playerCount === '5' ? 'form-group col-md-4 col-sm-12' : 'hidden'}>
                        <input
                            required={this.state.playerCount === '5'}
                            type="text"
                            className={"form-control"}
                            placeholder="Spieler 5"
                            value={this.state.player5}
                            onChange={(e) => this.setState({player5: e.target.value})}
                        />
                    </div>
                    <div className="form-group mt-2">
                        <button
                            className="btn btn-primary"
                            type="submit"
                        >
                            <i className="fas fa-play"/>
                            &nbsp; Start &nbsp;
                            <i className="fas fa-play"/>
                        </button>
                    </div>
                </fieldset>
            </form>
        );
    }

}

export default NewDokoList;