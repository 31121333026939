import {
    ADD_DOKO_ENTRY, ADD_KANA_ENTRY,
    ADD_LISTENTRY, CHANGE_MENU,
    DELETE_DOKO_ENTRY, DELETE_KANA_ENTRY,
    DELETE_LIST_ENTRY,
    START_OLD_DOKO_LIST, START_OLD_KANA_LIST,
    START_OLD_LIST
} from "../Actions";

const initialState = {
    gameList: [],
    dokoList: [],
    kanaList: [],
};

const listReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_LISTENTRY:
            console.log(state.gameList);
            return Object.assign({}, state, {
                gameList: [...state.gameList, action.listEntry],
            });
        case START_OLD_LIST:
            return Object.assign({}, state, {
               gameList: action.gameList
            });
        case DELETE_LIST_ENTRY:
            return Object.assign({}, state, {
               gameList: state.gameList.filter((entry) => entry.gameNumber !== action.gameNumber)
            });

        case ADD_DOKO_ENTRY:
            console.log(state.dokoList);
            return Object.assign({}, state, {
                dokoList: [...state.dokoList, action.dokoEntry]
            });
        case START_OLD_DOKO_LIST:
            return Object.assign({}, state, {
                dokoList: action.dokoList
            });
        case DELETE_DOKO_ENTRY:
            return Object.assign({}, state, {
                dokoList: state.dokoList.filter((entry) => entry.gameNumber !== action.gameNumber)
            });

        case ADD_KANA_ENTRY:
            console.log(state.kanaList);
            return Object.assign({}, state, {
                kanaList: [...state.kanaList, action.kanaEntry]
            });
        case START_OLD_KANA_LIST:
            return Object.assign({}, state, {
                kanaList: action.kanaList
            });
        case DELETE_KANA_ENTRY:
            return Object.assign({}, state, {
                kanaList: state.kanaList.filter((entry) => entry.gameNumber !== action.gameNumber)
            });
        case CHANGE_MENU:
            return initialState;
        default:
            return state;
    }
};

export default listReducer;