import React from "react";


class GameAdder extends React.Component {

    state = {
        listEntry: {
            gameNumber: this.props.gameList.length +1,
            gameMaker: (this.props.gameList.length +1)%4 === 1 ? this.props.player2 : this.props.player1,
            listId: this.props.listId,
            ansagen: "keine",
            kontra: "nein",
            mitOhne: "mit",
            buben: 0,
            punkte: 0,
            spielwert: "karo",
        }
    };

    handleSubmit(e) {
        e.preventDefault();
        this.props.sendListEntry(this.state.listEntry);
        this.props.changeGameFlag(false);
    }
    handleSpielwertChange = (e) => {
        switch (e.target.value) {
            case 'null':
                if (this.state.listEntry.punkte > 1 || this.state.listEntry.ansagen === 'schneider ange.' || this.state.listEntry === 'schwarz ange.') {
                    this.setState({
                            listEntry: {
                                ...this.state.listEntry, ...{
                                    punkte: 0,
                                    ansagen: "keine",
                                    spielwert: e.target.value
                                }
                            }
                        }
                    );
                } else {
                    this.setState({
                        listEntry: {...this.state.listEntry, ...{spielwert: e.target.value}}
                    });
                }
                break;
            case 'ramsch':
                if (this.state.listEntry.ansagen !== 'jungfrau') {
                    this.setState({
                        listEntry: {
                            ...this.state.listEntry, ...{
                                ansagen: "keine",
                                spielwert: e.target.value,
                                kontra: "nein"
                            }
                        }
                    });
                } else {
                    this.setState({
                        listEntry: {...this.state.listEntry, ...{spielwert: e.target.value}}
                    });
                }
                break;
            default:
                this.setState({
                    listEntry: {...this.state.listEntry, ...{spielwert: e.target.value}}
                });
        }
    };

    checkNullSpiel = () => {
        return (this.state.listEntry.spielwert === 'null');
    };
    checkRamsch = () => {
        return (this.state.listEntry.spielwert === 'ramsch');
    };

    pointsOrWinInput = () => {
        return (
            this.checkNullSpiel() ?
                <div className="form-group from-group-inline">
                    <label htmlFor="punkteLabel" className={"form-label"}>
                        Gewonnen
                    </label>
                    <select
                        className={"form-control"}
                        id="punkteLabel"
                        value={this.state.listEntry.punkte}
                        onChange={(e) => this.setState({listEntry: {...this.state.listEntry, ...{punkte: Number(e.target.value)}}})}
                    >
                        <option value="0">ja</option>
                        <option value="1">nein</option>
                    </select>
                </div>
                :
                <div className="form-group from-group-inline">
                    <label htmlFor="punkteLabel" className={"form-label"}>
                        Punkte
                    </label>
                    <input
                        className={"form-control"}
                        id="punkteLabel"
                        max="120"
                        min="0"
                        type="number"
                        style={{width:'5em'}}
                        value={this.state.listEntry.punkte}
                        onClick={(e) => {if(e.target.value === "0") e.target.value = ""}}
                        onChange={(e) => this.setState({listEntry: {...this.state.listEntry, ...{punkte: e.target.value}}})}
                    />
                </div>
        );
    };
    Ansagen = () => {
        if (this.checkNullSpiel()) {
            return (
                <div className="form-group from-group-inline">
                    <label htmlFor="ansagenLabel" className={"form-label"}>
                        Ansagen
                    </label>
                    <select
                        id="ansagenLabel"
                        className={"form-control"}
                        value={this.state.listEntry.ansagen}
                        onChange={(e) => this.setState({listEntry: {...this.state.listEntry, ...{ansagen: e.target.value}}})}
                    >
                        <option value="keine">keine</option>
                        <option value="hand">Hand</option>
                        <option value="ouvert">Ouvert</option>
                        <option value="handouvert">Hand Ouvert</option>
                        <option value="revolution">Revolution</option>
                    </select>
                </div>
            );
        }
        if (this.checkRamsch()) {
            return (
                <div className="form-group from-group-inline">
                    <label htmlFor="ansagenLabel" className={"form-label"}>
                        Jungfrau
                    </label>
                    <select
                        className={"form-control"}
                        id="ansagenLabel"
                        value={this.state.listEntry.ansagen}
                        onChange={(e) => this.setState({listEntry: {...this.state.listEntry, ...{ansagen: e.target.value}}})}
                    >
                        <option value="keine">nein</option>
                        <option value="jungfrau">Jungfrau</option>
                    </select>
                </div>
            );
        }
        return (
            <div className="form-group from-group-inline">
                <label htmlFor="ansagenLabel" className={"form-label"}>
                    Ansagen
                </label>
                <select
                    className={"form-control"}
                    id="ansagenLabel"
                    value={this.state.listEntry.ansagen}
                    onChange={(e) => this.setState({listEntry: {...this.state.listEntry, ...{ansagen: e.target.value}}})}
                >
                    <option value="keine">keine</option>
                    <option value="hand">Hand</option>
                    <option value="schneider ange.">Schneider ange.</option>
                    <option value="schwarz ange.">Schwarz ange.</option>
                    <option value="ouvert">Ouvert</option>
                </select>
            </div>)
    };

    render() {
        return (
            <form
                className=""
                onSubmit={e => this.handleSubmit(e)}
            >
                <fieldset>
                    <div className="flexAdderContainer" style={{padding: "10px 5px"}}>
                        <div className="form-group from-group-inline">
                            <label htmlFor="gameMakerLabel" className={"form-label"}>
                                Spieler
                            </label>
                            <select
                                className={"form-control"}
                                id="gameMakerLabel"
                                value={this.state.listEntry.gameMaker}
                                onChange={(e) => this.setState({listEntry: {...this.state.listEntry, ...{gameMaker: e.target.value}}})}
                            >
                                {this.props.playerCount === "fourPlayer" && this.state.listEntry.gameNumber%4 === 1 ? '' : <option value={this.props.player1}>{this.props.player1}</option>}
                                {this.props.playerCount === "fourPlayer" && this.state.listEntry.gameNumber%4 ===2 ? '' : <option value={this.props.player2}>{this.props.player2}</option>}
                                {this.props.playerCount === "fourPlayer" && this.state.listEntry.gameNumber%4 === 3 ? '' : <option value={this.props.player3}>{this.props.player3}</option>}
                                {this.props.playerCount === 'threePlayer' || this.state.listEntry.gameNumber%4 === 0 ? '' :
                                    <option value={this.props.player4}>{this.props.player4}</option>}
                            </select>
                        </div>
                        <div className="form-group from-group-inline">
                            <label htmlFor="spielWertLabel" className={"form-label"}>
                                Spiel
                            </label>
                            <select
                                className={"form-control"}
                                id="spielWertLabel"
                                value={this.state.listEntry.spielwert}
                                onChange={this.handleSpielwertChange}
                            >
                                <option value="karo">Karo</option>
                                <option value="herz">Herz</option>
                                <option value="pik">Pik</option>
                                <option value="kreuz">Kreuz</option>
                                <option value="null">Null</option>
                                <option value="grand">Grand</option>
                                <option value="ramsch">Ramsch</option>
                            </select>
                        </div>
                        {this.pointsOrWinInput()}
                        {this.checkNullSpiel() || this.checkRamsch() ? <React.Fragment/> :
                            <div className="form-group from-group-inline">
                                <label htmlFor="mitOhneLabel" className={"form-label"}>
                                    +/-
                                </label>
                                <select
                                    className={"form-control"}
                                    id="mitOhneLabel"
                                    value={this.state.listEntry.mitOhne}
                                    onChange={(e) => this.setState({listEntry: {...this.state.listEntry, ...{mitOhne: e.target.value}}})}
                                >
                                    <option value="mit">mit</option>
                                    <option value="ohne">ohne</option>
                                </select>
                            </div>}
                        {this.checkNullSpiel() || this.checkRamsch() ? <React.Fragment/> :
                            <div className="form-group from-group-inline">
                                <label htmlFor="bubenLabel" className={"form-label"}>
                                    Buben
                                </label>
                                <input
                                    className={"form-control"}
                                    id="bubenLabel"
                                    max={this.state.listEntry.spielwert === "grand" ? "4" : "11"}
                                    min="1"
                                    type="number"
                                    style={{width:'5em'}}
                                    value={this.state.listEntry.buben}
                                    onClick={(e) => {if(e.target.value === "0") e.target.value = ""}}
                                    onChange={(e) => this.setState({listEntry: {...this.state.listEntry, ...{buben: Number(e.target.value)}}})}
                                />
                            </div>}
                        {this.Ansagen()}
                        <div className="form-group from-group-inline">
                            <label htmlFor="kontraLabel" className={"form-label"}>Kontra</label>
                            <select
                                className={"form-control"}
                                id="kontraLabel"
                                value={this.state.listEntry.kontra}
                                onChange={(e) => this.setState({listEntry: {...this.state.listEntry, ...{kontra: e.target.value}}})}
                            >
                                <option value="nein">nein</option>
                                <option value="kontra">Kontra</option>
                                <option value="re">Re</option>
                                <option value="sup">Sup</option>
                                <option value="hirsch">Hirsch</option>
                            </select>
                        </div>
                        <div className="form-group from-group-inline">
                            <label htmlFor="buttonLabel" className={"form-label"}>Hinzufügen</label>
                            <button
                                id="buttonLabel"
                                type="submit"
                                className="btn btn-success form-control"
                                style={{width:'80px', display:'block'}}
                            >
                                <i className="fas fa-dice"/>
                            </button>
                        </div>
                    </div>
                </fieldset>
            </form>
        );
    }

}

export default GameAdder;

