import {connect} from "react-redux";
import Doko from "../../Components/Doko/Doko";


const mapStateToProps = state => {
    return {
        //addGameFlag: state.PropertyReducer.addGameFlag
    }
};

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps,mapDispatchToProps)(Doko);