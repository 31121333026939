import {
    ACHTFACH_PUNKTE,
    EINFACH_PUNKTE,
    HAND_ANSAGE,
    HAND_OUVERT_ANSAGE, JUNGFRAU,
    KEINE_ANSAGE,
    OUVERT_ANSAGE, REVOLUTION,
    SCHNEIDER_ANSAGE,
    SCHWARZ_ANSAGE, SECHZEHNFACH_PUNKTE, VIERFACH_PUNKTE, ZWEIFACH_PUNKTE
} from "../../Skat/GameTableBody";

export function calculateSkatStatistik(statistik, list, player, datum) {
    const parseSpielwert = (spielwert) => {
        switch (spielwert.toLowerCase()) {
            case "karo":
                return 9;
            case "herz":
                return 10;
            case "pik":
                return 11;
            case "kreuz":
                return 12;
            case "null":
                return 23;
            case "ramsch":
                return "R";
            case "grand":
                return 24;
            default:
                return 0;
        }
    };
    const parseAnsagen = (ansage) => {
        switch (ansage.toLowerCase()) {
            case "keine":
                return KEINE_ANSAGE;
            case "hand":
                return HAND_ANSAGE;
            case "schneider ange.":
                return SCHNEIDER_ANSAGE;
            case "schwarz ange.":
                return SCHWARZ_ANSAGE;
            case "ouvert":
                return OUVERT_ANSAGE;
            case "handouvert":
                return HAND_OUVERT_ANSAGE;
            case "revolution":
                return REVOLUTION;
            case "jungfrau":
                return JUNGFRAU;
            default:
                return KEINE_ANSAGE;
        }
    };
    const parseKontra = (kontra) => {
        switch (kontra.toLowerCase()) {
            case "nein":
                return EINFACH_PUNKTE;
            case "kontra":
                return ZWEIFACH_PUNKTE;
            case "re":
                return VIERFACH_PUNKTE;
            case "sup":
                return ACHTFACH_PUNKTE;
            case "hirsch":
                return SECHZEHNFACH_PUNKTE;
            default:
                return EINFACH_PUNKTE
        }
    };

    const calculateWinner = entry => {
        const {punkte} = entry;
        const ansagen = parseAnsagen(entry.ansagen);
        const spielwert = parseSpielwert(entry.spielwert);
        if (spielwert === 23) {
            return (Number(punkte) === 0);
        }
        if (spielwert === "R") {
            return false;
        }
        switch (ansagen) {
            case SCHNEIDER_ANSAGE:
                return (punkte > 89);
            case SCHWARZ_ANSAGE:
                return (punkte > 119);
            default:
                return (punkte > 60);
        }
    };
    const calculatePoints = entry => {
        let {buben, punkte} = entry;
        const ansagen = parseAnsagen(entry.ansagen);
        const spielwert = parseSpielwert(entry.spielwert);
        const kontra = parseKontra(entry.kontra);
            if (spielwert === 23) {
                switch (ansagen) {
                    case REVOLUTION:
                        return 92 * kontra;
                    case HAND_OUVERT_ANSAGE:
                        return 59 * kontra;
                    case OUVERT_ANSAGE:
                        return 46 * kontra;
                    case HAND_ANSAGE:
                        return 35 * kontra;
                    default:
                        return 23 * kontra;
                }
            }
            if (spielwert === "R") {
                if (ansagen === JUNGFRAU) {
                    return (punkte)
                }
                return punkte / 2;
            }

            if (punkte > 89 || punkte < 31) {
                buben = buben + 1;
            }
            if (punkte > 119 || punkte < 1) {
                buben = buben + 1;
            }
            return ((buben + 1 + (ansagen < HAND_OUVERT_ANSAGE ? ansagen : 0)) * spielwert * kontra);
        };

    if (!statistik) {
        statistik = {
            player: player, listen: 0, gesamt: 0, punkte: 0,
            gegeng: 0, allein: 0, alleing: 0,
            karo: 0, herz: 0, pik: 0, kreuz: 0, null: 0, grand: 0, ramsch: 0,
            normalg: 0, handg: 0, schneiderg: 0, schwarzg: 0, ouvertg: 0,
            normalv: 0, handv: 0, schneiderv: 0, schwarzv: 0, ouvertv: 0,
            farbeg: 0, farbev: 0, grandg: 0, grandv: 0, nullg: 0, nullv: 0, ramschg: 0, ramschv: 0,
            verlauf: []
            // {datum: 0, winrate: 0, allein: 0}]
        };
    }

    let verlaufObj = {datum: datum, winrate: 0, allein: 0};
    statistik.listen += 1;

    list.forEach(entry => {
        const isWon = calculateWinner(entry);
        const points = calculatePoints(entry);  // punkte falls gewonnen

        statistik.gesamt += 1;
        if (entry.gameMaker === player) { // Solospiel
            statistik.allein += 1;
            verlaufObj.allein += 1;
            if (isWon) {                     // Solo win
                statistik.punkte += (points + 50);
                statistik.alleing += 1;
                verlaufObj.winrate += 1;
                switch (entry.ansagen) {
                    case "keine":
                        if(entry.punkte < 90) { statistik.normalg += 1; }
                        break;
                    case "hand":
                        statistik.handg += 1;
                        break;
                    case "schneider ange.":
                        statistik.handg += 1;
                        break;
                    case "schwarz ange.":
                        statistik.handg += 1;
                        break;
                    case "ouvert":
                        statistik.handg += 1;
                        statistik.ouvertg += 1;
                        break;
                    default:
                }
                if (entry.punkte > 90) {
                    statistik.schneiderg += 1;
                }
                if (entry.punkte === 120) {
                    statistik.schwarzg += 1;
                }
                switch (entry.spielwert) {
                    case "karo":
                        statistik.karo += 1;
                        statistik.farbeg += 1;
                        break;
                    case "herz":
                        statistik.herz += 1;
                        statistik.farbeg += 1;
                        break;
                    case "pik":
                        statistik.pik += 1;
                        statistik.farbeg += 1;
                        break;
                    case "kreuz":
                        statistik.kreuz += 1;
                        statistik.farbeg += 1;
                        break;
                    case "null":
                        statistik.null += 1;
                        statistik.nullg += 1;
                        break;
                    case "grand":
                        statistik.grand += 1;
                        statistik.grandg += 1;
                        break;
                    case "ramsch":
                        statistik.ramsch += 1;
                        statistik.ramschg += 1;
                        break;
                    default:
                }
            }
            if (!isWon) {                     // Solo fail
                statistik.punkte -= ((points * 2) + 50);
                switch (entry.ansagen) {
                    case "keine":
                        if(entry.punkte > 30) { statistik.normalv += 1; }
                        break;
                    case "hand":
                        statistik.handv += 1;
                        break;
                    case "schneider ange.":
                        statistik.handv += 1;
                        break;
                    case "schwarz ange.":
                        statistik.handv += 1;
                        break;
                    case "ouvert":
                        statistik.handv += 1;
                        statistik.ouvertv += 1;
                        break;
                    default:
                }
                if (entry.punkte < 30) {
                    statistik.schneiderv += 1;
                }
                if (entry.punkte === 0) {
                    statistik.schwarzv += 1;
                }
                switch (entry.spielwert) {
                    case "karo":
                        statistik.karo += 1;
                        statistik.farbev += 1;
                        break;
                    case "herz":
                        statistik.herz += 1;
                        statistik.farbev += 1;
                        break;
                    case "pik":
                        statistik.pik += 1;
                        statistik.farbev += 1;
                        break;
                    case "kreuz":
                        statistik.kreuz += 1;
                        statistik.farbev += 1;
                        break;
                    case "null":
                        statistik.null += 1;
                        statistik.nullv += 1;
                        break;
                    case "grand":
                        statistik.grand += 1;
                        statistik.grandv += 1;
                        break;
                    case "ramsch":
                        statistik.ramsch += 1;
                        statistik.ramschv += 1;
                        break;
                    default:
                }
            }
        }
        if (entry.gameMaker !== player) { // Gegenspiel
            if (!isWon) {                     // Gegner failed
                statistik.gegeng += 1;
                if (list.playerCount === "threePlayer") {
                    statistik.punkte += 40;
                } else {
                    statistik.punkte += 30;
                }
            }
        }
    });

    verlaufObj.winrate = verlaufObj.allein ? verlaufObj.winrate / verlaufObj.allein : 0;
    verlaufObj.allein = list.length ? verlaufObj.allein / list.length : 0;
    statistik.verlauf.push(verlaufObj);
    return statistik;
}

