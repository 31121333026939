import {connect} from "react-redux";
import DokoTable from "../../Components/Doko/DokoTable";


const mapStateToProps = state => {
    return {
        addGameFlag: state.PropertyReducer.addGameFlag,
    }
};

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps,mapDispatchToProps)(DokoTable);