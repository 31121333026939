import React from "react";
import {reFinalPoints, isSolo, sumPoints, placement} from "./DokoTableCalculation";


class DokoTableBody extends React.Component {

    render() {

        const gameArray = sumPoints(this.props.dokoList, this.props.playerCount);

        return (
            <React.Fragment>
                {this.props.dokoList.map((entry, index) => {
                    let rePoints = reFinalPoints(entry.spiel, entry.re_punkte, entry.re_ansagen, entry.re_K,
                        entry.re_D, entry.re_F, entry.k_ansagen, entry.k_K, entry.k_D, entry.k_F);
                    const geber = this.props.playerCount === "4" ? ((entry.gameNumber -1) %4 +1) : ((entry.gameNumber -1)%5+1);
                    return (
                        <tr key={"dokotablebody" + index} className={this.props.playerCount === "4" ? "geberLinesFour" : "geberLinesFive"}>
                            <td  className="darkCell">{entry.gameNumber}&nbsp;
                                {entry.gameNumber === this.props.dokoList.length && this.props.addGameFlag === false
                                    ? <i
                                        className="fas fa-trash-alt"
                                        id="littleIcon"
                                        onClick={() => {
                                            if(window.confirm("Letzten Eintrag löschen?")) {
                                                this.props.deleteDokoEntry(this.props.listId, entry.gameNumber)}
                                        }}
                                        style={{fontSize: '10px', cursor:'pointer'}}/>
                                    : <div/>
                                }
                            </td>
                            <td className="borderRight darkCell">{entry.spiel}</td>
                            <td>
                                {entry.re_ansagen === "6" ? "Schw." : ""}
                                {entry.re_ansagen === "5" ? "U30" : ""}
                                {entry.re_ansagen === "4" ? "U60" : ""}
                                {entry.re_ansagen === "3" ? "U90" : ""}
                                {entry.re_ansagen === "2" ? "Re" : ""}
                                {entry.re_F === "0" ? "" : " " + "F".repeat(parseInt(entry.re_F))}
                                {entry.re_D === "0" ? "" : " " + "D".repeat(parseInt(entry.re_D))}
                                {entry.re_K === "0" ? "" : " " + "K".repeat(parseInt(entry.re_K))}
                            </td>
                            <td>{rePoints}</td>
                            <td className="borderRight">{entry.re_punkte}</td>
                            <td>{240 - entry.re_punkte}</td>
                            <td>{isSolo(entry.spiel) ? rePoints / (-3) : -rePoints}</td>
                            <td className="borderRight">
                                {entry.k_K === "0" ? "" : "K".repeat(parseInt(entry.k_K))}
                                {entry.k_D === "0" ? "" : " " + "D".repeat(parseInt(entry.k_D))}
                                {entry.k_F === "0" ? "" : " " + "F".repeat(parseInt(entry.k_F))}
                                {entry.k_ansagen === "6" ? " Schw." : ""}
                                {entry.k_ansagen === "5" ? " U30" : ""}
                                {entry.k_ansagen === "4" ? " U60" : ""}
                                {entry.k_ansagen === "3" ? " U90" : ""}
                                {entry.k_ansagen === "2" ? " Re" : ""}
                            </td>
                            <td className={geber === 1 ? "borderRight darkCell" : "borderRight"}>{gameArray[entry.gameNumber - 1][0]}</td>
                            <td className={geber === 2 ? "borderRight darkCell" : "borderRight"}>{gameArray[entry.gameNumber - 1][1]}</td>
                            <td className={geber === 3 ? "borderRight darkCell" : "borderRight"}>{gameArray[entry.gameNumber - 1][2]}</td>
                            <td className={geber === 4 ? "borderRight darkCell" : "borderRight"}>{gameArray[entry.gameNumber - 1][3]}</td>
                            {this.props.playerCount === "5" ? <td className={geber === 5 ? "darkCell" : ""}>{gameArray[entry.gameNumber - 1][4]}</td> :
                                <React.Fragment/>}
                        </tr>
                    )
                })}
                <tr className="borderTop">
                    <td colSpan="8"/>
                    <td>{this.props.dokoList.length > 0 ? placement(gameArray[this.props.dokoList.length - 1],0) : "--1--"}</td>
                    <td>{this.props.dokoList.length > 0 ? placement(gameArray[this.props.dokoList.length - 1],1) : "--1--"}</td>
                    <td>{this.props.dokoList.length > 0 ? placement(gameArray[this.props.dokoList.length - 1],2) : "--1--"}</td>
                    <td>{this.props.dokoList.length > 0 ? placement(gameArray[this.props.dokoList.length - 1],3) : "--1--"}</td>
                    {
                        this.props.playerCount === "5"
                            ? <td>{this.props.dokoList.length > 0 ? placement(gameArray[this.props.dokoList.length - 1],4) : "--1--"}</td>
                            : <React.Fragment/>
                    }
                </tr>
            </React.Fragment>
        );
    }

}

export default DokoTableBody;

