import React from "react";
import {connect} from "react-redux";
import {loadStatistik} from "../../Actions";

class PlayerSelector extends React.Component {

    state = {
        selectedPlayer: "",
    };

    handleSubmit = e => {
        e.preventDefault();
        this.state.selectedPlayer && this.props.loadStatistik(this.state.selectedPlayer);
    };

    render() {
        return (
            <div>
                <form className="pure-form" onSubmit={e => this.handleSubmit(e)}>
                    <legend>Statistik</legend>
                    <fieldset>
                        <label htmlFor="playerInput">für </label>
                        <input id="playerInput" type="text" placeholder="Spielername"
                               value={this.state.selectedPlayer}
                               onChange={e => this.setState({selectedPlayer: e.target.value})}
                        />
                        <button type="submit">abrufen</button>
                    </fieldset>
                </form>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadStatistik: player => dispatch(loadStatistik(player))
    }
};

export default connect(null, mapDispatchToProps)(PlayerSelector);
