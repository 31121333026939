import React from "react";
import {
    CHOOSE_DOKO,
    CHOOSE_KANA,
    CHOOSE_LIST,
    DOKO_IN_PROGRESS,
    GAME_IN_PROGRESS,
    KANA_IN_PROGRESS,
    LOGIN, STATISTIK
} from "../Actions";
import MainMenu from "./Skat/MainMenu";
import GameContainer from "../Container/Skat/GameContainer";
import LoginMenu from "./Login/LoginMenu";
import DokoContainer from "../Container/Doko/DokoContainer";
import DokoTableContainer from "../Container/Doko/DokoTableContainer";
import KanaContainer from "../Container/Kana/KanaContainer";
import KanaTableContainer from "../Container/Kana/KanaTableContainer";
import StatistikMain from "./Statistik/StatistikMain";

export const VERSION = "v1.30";

class App extends React.Component {

    switchComponent = (menuFlag) => {
        switch (menuFlag) {
            case LOGIN:
                return (<LoginMenu/>);
            case CHOOSE_LIST:
                return (<MainMenu/>);
            case GAME_IN_PROGRESS:
                return (<GameContainer/>);
            case CHOOSE_DOKO:
                return (<DokoContainer/>);
            case DOKO_IN_PROGRESS:
                return(<DokoTableContainer/>);
            case CHOOSE_KANA:
                return (<KanaContainer/>);
            case KANA_IN_PROGRESS:
                return(<KanaTableContainer/>);
            case STATISTIK:
                return <StatistikMain/>;
            default:
                console.log("Fehler, Komponente nicht gefunden :o");
                return <div/>
        }
    };

    render() {
        return (
            <div>
                {this.props.eventFlag &&
                    <div
                        style={{backgroundColor:'yellow', padding:'8px', margin:'2px', fontWeight:'bold'}}
                        onClick={() => this.props.finishEvent()}
                    >
                        {this.props.eventMsg}
                    </div>
                }
                {this.switchComponent(this.props.menuFlag)}
            </div>
        );
    }

}

export default App;
