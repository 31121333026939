import React from "react";
import {GAME_IN_PROGRESS} from "../../Actions";


class MMNewList extends React.Component {

    state = {
        accountName: this.props.accountName,
        playerCount: 'threePlayer',
        player1: '',
        player2: '',
        player3: '',
        player4: '',
        menuFlag: GAME_IN_PROGRESS,
        rules: 1000,
        game: 'Skat',
    };

    handleRadioChange = (e) => {
        this.setState({
            playerCount: e.target.value
        });
    };

    handleSubmit(e) {
        e.preventDefault();
        this.props.retrieveListId(this.state);
    }

    render() {
        return (
            <form className=""
                  onSubmit={e => this.handleSubmit(e)}
            >
                <fieldset>
                    <div className="mt-4">
                        <h2>Neue Skat-Liste starten</h2>
                    </div>
                    <div className="form-check form-check-inline">
                        <input id="threePlayer" type="radio" name="playerRadio" value="threePlayer"
                               checked={this.state.playerCount === 'threePlayer'}
                               onChange={this.handleRadioChange} className={"form-check-input"}
                        />
                        <label htmlFor="threePlayer" className="form-check-label">
                            &nbsp;3 Spieler
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input id="fourPlayer" type="radio" name="playerRadio" value="fourPlayer"
                               checked={this.state.playerCount === 'fourPlayer'}
                               onChange={this.handleRadioChange} className={"form-check-input"}
                        />
                        <label htmlFor="fourPlayer" className="form-check-label">
                            &nbsp;4 Spieler
                        </label>
                    </div>
                    <div className="form-group col-md-4 col-sm-12">
                        <input
                            className="form-control"
                            required
                            type="text"
                            placeholder="Spieler 1"
                            value={this.state.player1}
                            onChange={(e) => this.setState({player1: e.target.value})}
                        />
                    </div>
                    <div className="form-group col-md-4 col-sm-12">
                        <input
                            className="form-control"
                            required
                            type="text"
                            placeholder="Spieler 2"
                            value={this.state.player2}
                            onChange={(e) => this.setState({player2: e.target.value})}
                        />
                    </div>
                    <div className="form-group col-md-4 col-sm-12">
                        <input
                            className="form-control"
                            required
                            type="text"
                            placeholder="Spieler 3"
                            value={this.state.player3}
                            onChange={(e) => this.setState({player3: e.target.value})}
                        />
                    </div>
                    <div className={this.state.playerCount === 'fourPlayer' ? 'form-group col-md-4 col-sm-12' : 'hidden'}>
                        <input
                            required={this.state.playerCount === 'fourPlayer'}
                            type="text"
                            className="form-control"
                            placeholder="Spieler 4"
                            value={this.state.player4}
                            onChange={(e) => this.setState({player4: e.target.value})}
                        />
                    </div>
                    <div className="form-group  mt-2">
                        <button
                            className="btn btn-primary"
                            type="submit"
                        >
                            <i className="fas fa-play"/>
                            &nbsp; Start &nbsp;
                            <i className="fas fa-play"/>
                        </button>
                    </div>
                </fieldset>
            </form>
        );
    }

}

export default MMNewList;