import React from "react";
import StatistikSkat from "./StatistikSkat";

class StatistikContent extends React.Component {

    render() {
        return (
            <React.Fragment>
                {this.props.statistik.player
                    ? <div>
                        <StatistikSkat statistik={this.props.statistik}/>
                    </div>
                    : <div> ... kein Spieler gewählt ... </div>
                }
            </React.Fragment>
        );
    }

}

export default StatistikContent;

