import api from "../axios/api";
import {calculateSkatStatistik} from "../Components/Statistik/calculate/skat";

export const CHANGE_MENU = 'CHANGE_MENU';
export const SET_SKAT = 'SET_SKAT';
export const ADD_LISTENTRY = 'ADD_LISTENTRY';
export const ADDGAME_FLAG = 'ADDGAME_FLAG';
export const SET_OLD_LISTS = 'SET_OLD_LISTS';
export const START_OLD_LIST = 'START_OLD_LIST';
export const DELETE_LIST_ENTRY = 'DELETE_LIST_ENTRY';
export const ACCOUNT_CREATED = 'ACCOUNT_CREATED';
export const SET_LOGIN = 'SET_LOGIN';
export const SET_LOGOUT = 'SET_LOGOUT';
export const MAIL_SUCCESS = 'MAIL_SUCCESS';
export const MAIL_ERROR = 'MAIL_ERROR';
export const EVENT_FINISHED = 'EVENT_FINISHED';

export const ADD_DOKO_ENTRY = 'ADD_DOKO_ENTRY';
export const SET_OLD_DOKOLISTS = 'SET_OLD_DOKOLISTS';
export const START_OLD_DOKO_LIST = 'laksdfjalkdjflakj';
export const DELETE_DOKO_ENTRY = 'afdadf adfadsfadsf';

export const ADD_KANA_ENTRY = 'adlkjflakdfjflkajdfla';
export const SET_OLD_KANALISTS = '3ojiqerroqqrr323';
export const START_OLD_KANA_LIST = 'lpoirtopirtowrt';
export const DELETE_KANA_ENTRY = 'kikiiieieieieie';
export const LOAD_STATISTIK = "LOAD STATISTKJL";


export const LOGIN = 1;
export const CHOOSE_LIST = 2;
export const GAME_IN_PROGRESS = 3;
export const CHOOSE_DOKO = 4;
export const DOKO_IN_PROGRESS = 5;
export const CHOOSE_KANA = 6;
export const KANA_IN_PROGRESS = 7;
export const STATISTIK = 8;

export const setCookie = (jwt, days, name = "jwt") => {
    let d = new Date();
    d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
    document.cookie = `${name}=${jwt}; expires=${d.toUTCString()}; path=/`
};

export const getCookie = cname => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');

    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length)
        }
    }

    return "";
};

export const loadStatistik = player => {
    let jwt = getCookie('jwt');
    if (jwt === "") {
        return (dispatch) => dispatch({
            type: CHANGE_MENU,
            menuFlag: LOGIN
        })
    }
    return (dispatch) => {
        let statistik;
        api.post(
            'statistik/getLists.php',
            Object.assign({}, {player: player}, {jwt: jwt})
        ).then((response) => {
            response.data.lists.forEach(list => {
                switch (list.game) {
                    case "Skat":
                        statistik = calculateSkatStatistik(statistik, response.data.games[list.listId], player, list.gameDate);
                        break;
                    case "Doko":
                        break;
                    case "Kana":
                        break;
                    default:
                }
            });
            console.log(response.data, statistik);
            dispatch({
                type: LOAD_STATISTIK,
                statistik: statistik,
            });
        }).catch((err) => {
            console.log("Fehler beim laden der Statistik.");
            console.log(err);
        });
    }
};

export const postLogin = (data) => {
    return (dispatch) => {
        api.post(
            'account/login.php',
            data,
        ).then((response) => {
            dispatch(setLogin({
                jwt: response.data.jwt,
                username: data.username,
            }));
        }).catch((err) => {
            console.log(err);
        })
    }
};

export const setLogin = (data) => {
    setCookie(data.jwt, 1);
    setCookie(data.username, 1, "username");
    return (dispatch) => {
        dispatch({
            type: SET_LOGIN,
            accountName: data.username,
        })
    }
};

export const logMeOut = () => {
    return (dispatch) => {
        setCookie("", 1);
        dispatch({
            type: SET_LOGOUT,
        })
    }
};

export const createAccount = (user) => {
    return (dispatch) => {
        api.post(
            'account/createUser.php',
            user
        ).then((response) => {
            console.log(response.data);
            dispatch({
                type: ACCOUNT_CREATED,
                newAccount: response.data.sipCode,
                // 0 = succ, 1 = name taken, 2 = err
            })
        }).catch((err) => {
            console.log(err);
            dispatch({
                type: ACCOUNT_CREATED,
                newAccount: 2,
            })
        })
    }
};

export const setMenuFlag = menuFlag => ({
    type: CHANGE_MENU,
    menuFlag: menuFlag
});

export const addGameFlag = flag => ({
    type: ADDGAME_FLAG,
    addGameFlag: flag
});

export const retrieveListId = (metaData) => {
    let jwt = getCookie('jwt');
    if (jwt === "") {
        return (dispatch) => dispatch({
            type: CHANGE_MENU,
            menuFlag: LOGIN
        })
    }

    return (dispatch) => {
        api.post(
            'metaData/createList.php',
            Object.assign({}, metaData, {jwt: jwt})
        ).then((response) => {
            console.log(response);
            dispatch(setMetaData(metaData, response.data.listId));
        }).catch((err) => {
            console.log("Fehler beim speichern des Listen-Eintrags.");
            console.log(err);
        });
    }
};

const setMetaData = (metaData, listId) => ({
    type: SET_SKAT,
    listId: listId,
    accountName: metaData.accountName,
    menuFlag: metaData.menuFlag,
    playerCount: metaData.playerCount,
    player1: metaData.player1,
    player2: metaData.player2,
    player3: metaData.player3,
    player4: metaData.player4,
    player5: metaData.player5,
    rules: metaData.rules,
    gameDate: metaData.gameDate || new Date().toString(),
});

export const sendMailAppend = (to, subject, message, appendix) => {

    let jwt = getCookie('jwt');
    if (jwt === "") {
        return (dispatch) => dispatch({
            type: CHANGE_MENU,
            menuFlag: LOGIN
        })
    }

    return (dispatch) => {
        api.post(
            'account/sendMailAppend.php',
            Object.assign({}, {
                to: to,
                subject: subject,
                message: message,
                appendix: appendix,
                jwt: jwt
            })
        ).then(() => {
            dispatch({type: MAIL_SUCCESS});
        }).catch((err) => {
            dispatch({
                type: MAIL_ERROR,
            })
        });
    }
};

export const finishEvent = () => ({
    type: EVENT_FINISHED,
});

//--------------------------------------------------------------
//SKAT ---------------------------------------------------------

export const sendListEntry = (entry) => {
    let jwt = getCookie('jwt');
    if (jwt === "") {
        return (dispatch) => dispatch({
            type: CHANGE_MENU,
            menuFlag: LOGIN
        })
    }

    return (dispatch) => {
        api.post(
            'skatList/create.php',
            Object.assign({}, entry, {jwt: jwt})
        ).then(() => {
            dispatch(addListEntry(entry));
        }).catch((err) => {
            console.log("Fehler beim speichern des Listen-Eintrags.");
            console.log(err);
        });
    }
};
const addListEntry = entry => ({
    type: ADD_LISTENTRY,
    listEntry: entry
});

export const retrieveOldListIds = (accountName) => {
    return (dispatch) => {
        api.get(
            'metaData/getListIdsByAccountName.php', {
                params: {
                    accountName: accountName,
                    game: 'Skat'
                }
            }).then(
            (response) => {
                dispatch({
                    type: SET_OLD_LISTS,
                    oldLists: response.data.records || []
                })
            }).catch(
            (err) => {
                console.log("Fehler beim abrufen von alten Listen.");
                console.log(err);
            })
    }
};


export const retrieveList = (oldList) => {
    return (dispatch) => {
        api.get(
            'skatList/getByListId.php', {
                params: {listId: oldList.listId}
            }).then(
            (response) => {
                dispatch({
                    type: START_OLD_LIST,
                    listId: oldList.listId,
                    accountName: oldList.accountName,
                    playerCount: oldList.playerCount,
                    player1: oldList.player1,
                    player2: oldList.player2,
                    player3: oldList.player3,
                    player4: oldList.player4,
                    player5: "",
                    rules: oldList.rules,
                    gameDate: oldList.gameDate,
                    menuFlag: GAME_IN_PROGRESS,
                    gameList: response.data.records.map((entry) => {
                        return {
                            ansagen: entry.ansagen,
                            buben: parseInt(entry.buben),
                            gameMaker: entry.gameMaker,
                            gameNumber: parseInt(entry.gameNumber),
                            kontra: entry.kontra,
                            mitOhne: entry.mitOhne,
                            punkte: parseInt(entry.punkte),
                            spielwert: entry.spielwert,
                        }
                    })
                });
            }).catch(
            (err) => {
                console.log("Fehler beim öffnen der alten Liste");
                console.log(err);
            })
    }
};

export const deleteListEntry = (listId, gameNumber) => {
    let jwt = getCookie('jwt');
    if (jwt === "") {
        return (dispatch) => dispatch({
            type: CHANGE_MENU,
            menuFlag: LOGIN
        })
    }

    let params = new URLSearchParams();
    params.append("listId", listId);
    params.append("gameNumber", gameNumber);
    params.append("jwt", jwt);

    return (dispatch) => {
        api.get(
            'skatList/delete.php', {
                params: params
            }).then(
            () => {
                dispatch({
                    type: DELETE_LIST_ENTRY,
                    listId: listId,
                    gameNumber: gameNumber,
                })
            }).catch(
            (err) => {
                console.log("Fehler beim Löschen des Listeneintrags");
                console.log(err);
            })
    }
};

// ----------------------------------------------------------------------
// ------------------------DOKO -----------------------------------------

export const deleteDokoEntry = (listId, gameNumber) => {
    let jwt = getCookie('jwt');
    if (jwt === "") {
        return (dispatch) => dispatch({
            type: CHANGE_MENU,
            menuFlag: LOGIN
        })
    }

    let params = new URLSearchParams();
    params.append("listId", listId);
    params.append("gameNumber", gameNumber);
    params.append("jwt", jwt);

    return (dispatch) => {
        api.get(
            'dokoList/delete.php', {
                params: params
            }).then(
            () => {
                dispatch({
                    type: DELETE_DOKO_ENTRY,
                    listId: listId,
                    gameNumber: gameNumber,
                })
            }).catch(
            (err) => {
                console.log("Fehler beim Löschen des Listeneintrags");
                console.log(err);
            })
    }
};

export const sendDokoEntry = (entry) => {
    let jwt = getCookie('jwt');
    if (jwt === "") {
        return (dispatch) => dispatch({
            type: CHANGE_MENU,
            menuFlag: LOGIN
        })
    }

    return (dispatch) => {
        api.post(
            'dokoList/create.php',
            Object.assign({}, entry, {jwt: jwt})
        ).then(() => {
            dispatch(addDokoEntry(entry));
        }).catch((err) => {
            console.log("Fehler beim speichern des Listen-Eintrags.");
            console.log(err);
        });
    }
};
const addDokoEntry = (entry) => ({
    type: ADD_DOKO_ENTRY,
    dokoEntry: entry
});

export const retrieveOldDokoIds = (accountName) => {
    return (dispatch) => {
        api.get(
            'metaData/getListIdsByAccountName.php', {
                params: {
                    accountName: accountName,
                    game: 'Doko'
                }
            }).then(
            (response) => {
                dispatch({
                    type: SET_OLD_DOKOLISTS,
                    dokoListIds: response.data.records || []
                })
            }).catch(
            (err) => {
                console.log("Fehler beim abrufen von alten Listen.");
                console.log(err);
            })
    }
};

export const retrieveDokoList = (oldList) => {
    return (dispatch) => {
        api.get(
            'dokoList/getByListId.php', {
                params: {listId: oldList.listId}
            }).then(
            (response) => {
                dispatch({
                    type: START_OLD_DOKO_LIST,
                    listId: oldList.listId,
                    accountName: oldList.accountName,
                    playerCount: oldList.playerCount,
                    player1: oldList.player1,
                    player2: oldList.player2,
                    player3: oldList.player3,
                    player4: oldList.player4,
                    player5: oldList.player5,
                    rules: oldList.rules,
                    gameDate: oldList.gameDate,
                    menuFlag: DOKO_IN_PROGRESS,
                    dokoList: response.data.records,
                });
            }).catch(
            (err) => {
                console.log("Fehler beim öffnen der alten Liste");
                console.log(err);
            })
    }
};

// --------------------------------------------------------------------------------
// --------------------- KANASTER -------------------------------------------------


export const deleteKanaEntry = (listId, gameNumber) => {
    let jwt = getCookie('jwt');
    if (jwt === "") {
        return (dispatch) => dispatch({
            type: CHANGE_MENU,
            menuFlag: LOGIN
        })
    }

    let params = new URLSearchParams();
    params.append("listId", listId);
    params.append("gameNumber", gameNumber);
    params.append("jwt", jwt);

    return (dispatch) => {
        api.get(
            'kanaList/delete.php', {
                params: params
            }).then(
            () => {
                dispatch({
                    type: DELETE_KANA_ENTRY,
                    listId: listId,
                    gameNumber: gameNumber,
                })
            }).catch(
            (err) => {
                console.log("Fehler beim Löschen des Listeneintrags");
                console.log(err);
            })
    }
};

export const sendKanaEntry = (entry) => {
    let jwt = getCookie('jwt');
    if (jwt === "") {
        return (dispatch) => dispatch({
            type: CHANGE_MENU,
            menuFlag: LOGIN
        })
    }

    return (dispatch) => {
        api.post(
            'kanaList/create.php',
            Object.assign({}, entry, {jwt: jwt})
        ).then(() => {
            dispatch(addKanaEntry(entry));
        }).catch((err) => {
            console.log("Fehler beim speichern des Listen-Eintrags.");
            console.log(err);
        });
    }
};
const addKanaEntry = (entry) => ({
    type: ADD_KANA_ENTRY,
    kanaEntry: entry
});

export const retrieveOldKanaIds = (accountName) => {
    return (dispatch) => {
        api.get(
            'metaData/getListIdsByAccountName.php', {
                params: {
                    accountName: accountName,
                    game: 'Kana'
                }
            }).then(
            (response) => {
                dispatch({
                    type: SET_OLD_KANALISTS,
                    kanaListIds: response.data.records || []
                })
            }).catch(
            (err) => {
                console.log("Fehler beim abrufen von alten Listen.");
                console.log(err);
            })
    }
};

export const retrieveKanaList = (oldList) => {
    return (dispatch) => {
        api.get(
            'kanaList/getByListId.php', {
                params: {listId: oldList.listId}
            }).then(
            (response) => {
                dispatch({
                    type: START_OLD_KANA_LIST,
                    listId: oldList.listId,
                    accountName: oldList.accountName,
                    playerCount: oldList.playerCount,
                    player1: oldList.player1,
                    player2: oldList.player2,
                    player3: oldList.player3,
                    player4: oldList.player4,
                    player5: oldList.player5,
                    player6: "",
                    rules: oldList.rules,
                    gameDate: oldList.gameDate,
                    menuFlag: KANA_IN_PROGRESS,
                    kanaList: response.data.records,
                });
            }).catch(
            (err) => {
                console.log("Fehler beim öffnen der alten Liste");
                console.log(err);
            })
    }
};
