import {connect} from "react-redux";
import {addGameFlag, sendKanaEntry} from "../../Actions";
import AddKanaEntry from "../../Components/Kana/AddKanaEntry";


const mapStateToProps = state => {
    return {
        addGameFlag: state.PropertyReducer.addGameFlag,
        kanaList: state.ListReducer.kanaList,
        listId: state.PropertyReducer.listId,
        player1: state.PropertyReducer.player1,
        player2: state.PropertyReducer.player2,
        player3: state.PropertyReducer.player3,
        player4: state.PropertyReducer.player4,
        player5: state.PropertyReducer.player5,
        player6: "",
        playerCount: state.PropertyReducer.playerCount,
    }
};

const mapDispatchToProps = dispatch => ({
    sendKanaEntry: (entry) => dispatch(sendKanaEntry(entry)),
    changeGameFlag: (flag) => dispatch(addGameFlag(flag)),
});

export default connect(mapStateToProps,mapDispatchToProps)(AddKanaEntry);