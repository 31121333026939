import {connect} from "react-redux";
import KanaTableBody from "../../Components/Kana/KanaTableBody";
import {deleteKanaEntry} from "../../Actions";


const mapStateToProps = state => {
    return {
        addGameFlag: state.PropertyReducer.addGameFlag,
        listId: state.PropertyReducer.listId,
        kanaList: state.ListReducer.kanaList,
        playerCount: state.PropertyReducer.playerCount,
    }
};

const mapDispatchToProps = dispatch => ({
    deleteKanaEntry: (listId, gameNumber) => dispatch(deleteKanaEntry(listId, gameNumber)),
});

export default connect(mapStateToProps,mapDispatchToProps)(KanaTableBody);