import React from "react";
import CanvasJSReact from "./canvasJS/canvasjs.react";
import "./Statistik.scss";

class StatistikSkat extends React.Component {

    render() {
        const stati = this.props.statistik || {};
        return (
            <React.Fragment>
                <div className="statiHeadline">Skat-Statistik für {stati.player}</div>
                <div className="statiHeader">
                        <table className="headerItem"><tbody>
                            <tr><th colSpan="2" className="borderBottom">Listen</th></tr>
                            <tr><td>Listen</td><td>{stati.listen}</td></tr>
                            <tr><td>Spiele pro Liste</td><td>{Math.round(stati.gesamt/stati.listen*10)/10}</td></tr>
                            <tr><td className="borderBottom">Punkte pro Liste</td><td className="borderBottom">{Math.round(stati.punkte/stati.gesamt*10)/10}</td></tr>
                        </tbody></table>
                       <table className="headerItem"><tbody>
                            <tr><th colSpan="2" className="borderBottom">Spielverhalten</th></tr>
                            <tr><td>Häufigkeit Solospieler</td><td>{Math.round(stati.allein/stati.gesamt*1000)/10}%</td></tr>
                            <tr><td>Winrate Alleinspieler</td><td>{Math.round(stati.alleing/stati.allein*1000)/10}%</td></tr>
                            <tr><td>Winrate Gegenspieler</td><td>{Math.round(stati.gegeng/(stati.gesamt - stati.allein)*1000)/10}%</td></tr>
                       </tbody></table>
                </div>

                <div className="statiHeadline">Angesagte Spiele</div>
                <div className="statiPie">
                    <table className="headerItem"><tbody>
                        <tr><th>Solospiele</th><th>gew.</th><th>verl.</th></tr>
                        <tr><td>Farbe</td><td>{stati.farbeg}</td><td>{stati.farbev}</td></tr>
                        <tr><td>Null</td><td>{stati.nullg}</td><td>{stati.nullv}</td></tr>
                        <tr><td>Grand</td><td>{stati.grandg}</td><td>{stati.grandv}</td></tr>
                        <tr><td>Ramsch</td><td>{stati.ramschg}</td><td>{stati.ramschv}</td></tr>
                    </tbody></table>
                    <div className="pieItem">
                        <CanvasJSReact.CanvasJSChart options = {{
                            theme: "light1",
                            animationEnabled: true,
                            exportEnable: false,
                            width: 250, height: 180,
                            legend: {
                                dockInsidePlotArea: false,
                                verticalAlign: "center",
                                horizontalAlign: "right",
                            },
                            data: [{
                                type: "pie",
                                showInLegend: true,
                                legendText: "{label}",
                                toolTipContent: "{label}: {y}%",
                                indexLabel: "{y}%",
                                indexLabelPlacement: "inside",
                                dataPoints: [
                                    {y: Math.round(100/stati.allein*stati.karo), label: "Karo"},
                                    {y: Math.round(100/stati.allein*stati.herz), label: "Herz"},
                                    {y: Math.round(100/stati.allein*stati.pik), label: "Pik"},
                                    {y: Math.round(100/stati.allein*stati.kreuz), label: "Kreuz"},
                                    {y: Math.round(100/stati.allein*stati.null) , label: "Null"},
                                    {y: Math.round(100/stati.allein*stati.grand), label: "Grand"},
                                    {y: Math.round(100/stati.allein*stati.ramsch), label: "Ramsch"},
                                ]
                            }]
                        }}/>
                    </div>
                </div>

                <div className="statiHeadline">Erreichte Gewinn- und Verluststufen</div>
                <div className="statiSpiele">
                    <div className="spielItem" style={{width: `${100/stati.alleing*stati.normalg}%`, backgroundColor: "#99ff33"}}>
                       {`${Math.round(100/stati.alleing*stati.normalg)}%`} Normal
                    </div>
                    <div className="spielItem" style={{width: `${100/stati.alleing*stati.handg}%`, backgroundColor: "#53ff1a"}}>
                       {`${Math.round(100/stati.alleing*stati.handg)}%`} Hand
                    </div>
                    <div className="spielItem" style={{width: `${100/stati.alleing*stati.schneiderg}%`, backgroundColor: "#00e600"}}>
                       {`${Math.round(100/stati.alleing*stati.schneiderg)}%`} Schneider
                    </div>
                    <div className="spielItem" style={{width: `${100/stati.alleing*stati.schwarzg}%`, backgroundColor: "#22BB22"}}>
                       {`${Math.round(100/stati.alleing*stati.schwarzg)}%`} Schwarz
                    </div>
                    <div className="spielItem" style={{width: `${100/stati.alleing*stati.ouvertg}%`, backgroundColor: "#009900"}}>
                       {`${Math.round(100/stati.alleing*stati.ouvertg)}%`} Offen
                    </div>
                </div>
                <div className="statiSpiele">
                    <div className="spielItem" style={{width: `${100/(stati.allein-stati.alleing)*stati.normalv}%`, backgroundColor: "#ff4d4d"}}>
                        {`${Math.round(100/(stati.allein-stati.alleing)*stati.normalv)}%`} Normal
                    </div>
                    <div className="spielItem" style={{width: `${100/(stati.allein-stati.alleing)*stati.handv}%`, backgroundColor: "#ff0000"}}>
                        {`${Math.round(100/(stati.allein-stati.alleing)*stati.handv)}%`} Hand
                    </div>
                    <div className="spielItem" style={{width: `${100/(stati.allein-stati.alleing)*stati.schneiderv}%`, backgroundColor: "#cc0000"}}>
                        {`${Math.round(100/(stati.allein-stati.alleing)*stati.schneiderv)}%`} Schneider
                    </div>
                    <div className="spielItem" style={{width: `${100/(stati.allein-stati.alleing)*stati.schwarzv}%`, backgroundColor: "#990000"}}>
                        {`${Math.round(100/(stati.allein-stati.alleing)*stati.schwarzv)}%`} Schwarz
                    </div>
                    <div className="spielItem" style={{width: `${100/(stati.allein-stati.alleing)*stati.ouvertv}%`, backgroundColor: "#800000"}}>
                        {`${Math.round(100/(stati.allein-stati.alleing)*stati.ouvertv)}%`} Offen
                    </div>
                </div>

                <div className="statiHeadline">Lernkurve</div>
                <div className="statiCurve">
                    <CanvasJSReact.CanvasJSChart options = {{
                        animationEnabled: true,
                        axisY: {
                            suffix: "%",
                            includeZero: false,
                        },
                        height: 300,
                        legend: {
                            verticalAlign: "center",
                            horizontalAlign: "right",
                            dockInsidePlotArea: true,
                        },
                        toolTip: { shared: true, },
                        data: [{
                            type: "spline",
                            name: "Ist Solospieler",
                            showInLegend: true,
                            dataPoints: stati.verlauf.map(entry => {
                                return({y: Math.round(entry.allein * 1000)/10, label: (new Date(entry.datum)).toLocaleDateString()});
                            })
                        },{
                            type: "spline",
                            name: "Winrate Solospieler",
                            showInLegend: true,
                            dataPoints: stati.verlauf.map(entry => {
                                return({y: Math.round(entry.winrate * 1000)/10, label: (new Date(entry.datum)).toLocaleDateString()});
                            })
                        }],
                    }}/>
                </div>
            </React.Fragment>
        );
    }

}

export default StatistikSkat;

