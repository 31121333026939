import {connect} from "react-redux";
import OldKanaListItems from "../../Components/Kana/OldKanaListItems";
import {retrieveKanaList, retrieveOldKanaIds} from "../../Actions";


const mapStateToProps = state => {
    return {
        accountName: state.PropertyReducer.accountName,
        kanaListIds: state.PropertyReducer.kanaListIds,
    }
};

const mapDispatchToProps = dispatch => ({
    retrieveOldKanaIds: (accountName) => { dispatch(retrieveOldKanaIds(accountName))},
    retrieveKanaList: (listId) => { dispatch(retrieveKanaList(listId))},
});

export default connect(mapStateToProps,mapDispatchToProps)(OldKanaListItems);