import {connect} from "react-redux";
import AddDokoEntry from "../../Components/Doko/AddDokoEntry";
import {addGameFlag, sendDokoEntry} from "../../Actions";


const mapStateToProps = state => {
    return {
        addGameFlag: state.PropertyReducer.addGameFlag,
        dokoList: state.ListReducer.dokoList,
        listId: state.PropertyReducer.listId,
        player1: state.PropertyReducer.player1,
        player2: state.PropertyReducer.player2,
        player3: state.PropertyReducer.player3,
        player4: state.PropertyReducer.player4,
        player5: state.PropertyReducer.player5,
        playerCount: state.PropertyReducer.playerCount,
    }
};

const mapDispatchToProps = dispatch => ({
    sendDokoEntry: (entry) => dispatch(sendDokoEntry(entry)),
    changeGameFlag: (flag) => dispatch(addGameFlag(flag)),
});

export default connect(mapStateToProps,mapDispatchToProps)(AddDokoEntry);