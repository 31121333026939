import PropertyReducer from "./PropertyReducer";
import ListReducer from "./ListReducer";
import {combineReducers} from "redux";
import StatistikReducer from "./StatistikReducer";



export default combineReducers({
    PropertyReducer,
    ListReducer,
    StatistikReducer,
})

