import React from "react";


class DokoTableHeader extends React.Component {


    render() {
        return (
            <React.Fragment>
                <thead className="darkCell">
                <tr className="borderBottom">
                    <th rowSpan="2" style={{margin:'0px', padding:'0px'}}>
                        <button className="pure-button-primary"
                                type="normal"
                                style={{height:'2.5em', width:'80%', maxWidth: "40px", border:'1px solid black',
                                    margin:'0px', padding:'1px'}}
                                onClick={() => {this.props.changeGameFlag(!this.props.addGameFlag)}}
                        >
                            <i className="fas fa-plus-square"/>
                    </button>
                    </th>
                    <th rowSpan="2" className={"borderRight"}>Spiel</th>
                    <th colSpan="3" className={"borderRight"}>Re-Partei</th>
                    <th colSpan="3" className={"borderRight"}>Kontra-Partei</th>
                    <th colSpan={this.props.playerCount === '4' ? '8' : '10'}>
                        {"Datum: " + new Date(this.props.gameDate).toLocaleString()}
                    </th>
                </tr>
                <tr>
                    <th>Ansagen</th>
                    <th>Summe</th>
                    <th  className={"borderRight"}>Pkt</th>
                    <th>Pkt</th>
                    <th>Summe</th>
                    <th  className={"borderRight"}>Ansagen</th>
                    <th className={"borderRight"}>{this.props.player1}</th>
                    <th className={"borderRight"}>{this.props.player2}</th>
                    <th className={"borderRight"}>{this.props.player3}</th>
                    <th className={"borderRight"}>{this.props.player4}</th>
                    {this.props.playerCount === '4' ? <React.Fragment/> :
                        <React.Fragment>
                            <th>{this.props.player5}</th>
                        </React.Fragment>
                    }
                </tr>
                </thead>
            </React.Fragment>
        );
    }

}

export default DokoTableHeader;

