import React from "react";


class KanaTableHeader extends React.Component {


    render() {
        return (
            <React.Fragment>
                <thead className="darkCell">
                <tr>
                    <th rowSpan ="3" className="borderRight">
                        <button className="btn btn-primary"
                                type="normal"
                                style={{height:'45px', border:'1px solid black', width:'80%', padding: "0"}}
                                onClick={() => {this.props.changeGameFlag(!this.props.addGameFlag)}}
                        >
                            <i className="fas fa-plus-square"/>
                        </button>
                    </th>
                    <th colSpan="5" className="borderRight">Team1: {this.props.player1} & {this.props.player2}</th>
                    <th colSpan="4">Datum: {new Date(this.props.gameDate).toLocaleDateString()}</th>
                    <th colSpan="5" className="borderLeft">Team2: {this.props.player3} & {this.props.player4}</th>
                </tr>
                <tr>
                    <th colSpan="2">Knaster</th>
                    <th rowSpan="2">Hand</th>
                    <th rowSpan="2">Tisch</th>
                    <th rowSpan="2" className="borderRight">3er</th>
                    <th rowSpan="2">
                        <img
                            className="verticalText"
                            src="./images/vertikaleSchrift/runde.png"
                            alt="gew"
                        />
                    </th>
                    <th rowSpan="2" className="borderRight">
                        <img
                            className="verticalText"
                            src="./images/vertikaleSchrift/gesamt.png"
                            alt="gew"
                        />
                    </th>
                    <th rowSpan="2">
                        <img
                            className="verticalText"
                            src="./images/vertikaleSchrift/gesamt.png"
                            alt="gew"
                        />
                    </th>
                    <th rowSpan="2" className="borderRight">
                        <img
                            className="verticalText"
                            src="./images/vertikaleSchrift/runde.png"
                            alt="gew"
                        />
                    </th>
                    <th rowSpan="2">3er</th>
                    <th rowSpan="2">Tisch</th>
                    <th rowSpan="2">Hand</th>
                    <th colSpan="2">Knaster</th>
                </tr>
                <tr>
                    <th>rein</th>
                    <th>unrein</th>
                    <th>unrein</th>
                    <th>rein</th>
                </tr>
                </thead>
            </React.Fragment>
        );
    }

}

export default KanaTableHeader;

