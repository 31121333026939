import {connect} from "react-redux";
import DokoTableBody from "../../Components/Doko/DokoTableBody";
import {deleteDokoEntry} from "../../Actions";


const mapStateToProps = state => {
    return {
        addGameFlag: state.PropertyReducer.addGameFlag,
        listId: state.PropertyReducer.listId,
        dokoList: state.ListReducer.dokoList,
        playerCount: state.PropertyReducer.playerCount,
    }
};

const mapDispatchToProps = dispatch => ({
        deleteDokoEntry: (listId, gameNumber) => dispatch(deleteDokoEntry(listId, gameNumber)),
});

export default connect(mapStateToProps,mapDispatchToProps)(DokoTableBody);