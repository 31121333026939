import MenuNavi from "../Components/MenuNavi";
import {connect} from "react-redux";
import {logMeOut, setMenuFlag} from "../Actions";


const mapStateToProps = state => {
    return {
        menuFlag: state.PropertyReducer.menuFlag,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setMenuFlag: menuFlag => dispatch(setMenuFlag(menuFlag)),
        logMeOut: () => dispatch(logMeOut())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuNavi);