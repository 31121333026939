import React from "react";

import "./KanaTable.scss";
import AddKanaEntryContainer from "../../Container/Kana/AddKanaEntryContainer";
import KanaTableHeaderContainer from "../../Container/Kana/KanaTableHeaderContainer";
import KanaTableBodyContainer from "../../Container/Kana/KanaTableBodyContainer";
import MenuNaviContainer from "../../Container/MenuNaviContainer";

class DokoTable extends React.Component {

    render() {
        return (
            <React.Fragment>
                <MenuNaviContainer/>
                {this.props.addGameFlag ? <AddKanaEntryContainer/> : <div/>}
                <table className={"kanaTable"}>
                    <KanaTableHeaderContainer/>
                    <tbody>
                    <KanaTableBodyContainer/>
                    </tbody>
                </table>
            </React.Fragment>
        );
    }

}

export default DokoTable;

