import {
    ACCOUNT_CREATED,
    ADDGAME_FLAG,
    CHANGE_MENU,
    CHOOSE_LIST,
    EVENT_FINISHED,
    LOGIN,
    MAIL_ERROR,
    MAIL_SUCCESS,
    SET_LOGIN,
    SET_LOGOUT,
    SET_OLD_DOKOLISTS,
    SET_OLD_KANALISTS,
    SET_OLD_LISTS,
    SET_SKAT,
    START_OLD_DOKO_LIST,
    START_OLD_KANA_LIST,
    START_OLD_LIST
} from "../Actions";

const initialState = {
    accountName: "Default",
    newAccount: false,
    menuFlag: LOGIN, // ----------------------
    jwt: "",
    listId: 0,
    playerCount: '4',
    player1: "Silvio",
    player2: "a",
    player3: "b",
    player4: "c",
    player5: "d",
    comment: "",
    gameDate: new Date().toString(),
    rules: 2000,
    oldLists: [],
    dokoListIds: [],
    kanaListIds: [],
    addGameFlag: false,
    eventFlag: false,
    eventMsg: "",
};

const PropertyReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_MENU:
            return Object.assign({}, state, {
                menuFlag: action.menuFlag,
                addGameFlag: false
            });
        case ACCOUNT_CREATED:
            return Object.assign({}, state, {
                newAccount: action.newAccount
            });
        case SET_LOGIN:
            console.log("SET_LOGIN", action);
            return Object.assign({}, state, {
                accountName: action.accountName,
                jwt: action.jwt,
                menuFlag: CHOOSE_LIST,
            });
        case SET_LOGOUT: {
            return initialState;
        }
        case ADDGAME_FLAG:
            return Object.assign({}, state, {
                addGameFlag: action.addGameFlag
            });
        case SET_SKAT:
        case START_OLD_LIST:
        case START_OLD_DOKO_LIST:
        case START_OLD_KANA_LIST:
            return Object.assign({}, state, {
                listId: action.listId,
                playerCount: action.playerCount,
                player1: action.player1,
                player2: action.player2,
                player3: action.player3,
                player4: action.player4,
                player5: action.player5,
                player6: "",
                rules: action.rules,
                gameDate: action.gameDate,
                menuFlag: action.menuFlag,
            });
        case SET_OLD_LISTS:
            return Object.assign({}, state, {
                oldLists: action.oldLists,
            });
        case SET_OLD_DOKOLISTS:
            return Object.assign({}, state, {
                dokoListIds: action.dokoListIds,
            });
        case SET_OLD_KANALISTS:
            return Object.assign({}, state, {
                kanaListIds: action.kanaListIds,
            });
        case MAIL_ERROR:
            return Object.assign({}, state, {
                eventMsg: "Fehler beim senden der Mails.",
                eventFlag: true
            });
        case MAIL_SUCCESS:
            return Object.assign({}, state, {
                eventMsg: "Erfolgreich gesendet!",
                eventFlag: true
            });
        case EVENT_FINISHED:
            return Object.assign({}, state, {
                eventFlag: false
            });
        default:
            return state;
    }
};


export default PropertyReducer