import {connect} from "react-redux";
import GameTable from "../../Components/Skat/GameTable";
import {addGameFlag} from "../../Actions";


const mapStateToProps = state => {
    return {
        addGameFlag: state.PropertyReducer.addGameFlag,
        gameDate: state.PropertyReducer.gameDate,
        playerCount: state.PropertyReducer.playerCount,
        player1: state.PropertyReducer.player1,
        player2: state.PropertyReducer.player2,
        player3: state.PropertyReducer.player3,
        player4: state.PropertyReducer.player4
    }
};

const mapDispatchToProps = dispatch => ({
    changeGameFlag: (flag) => {dispatch(addGameFlag(flag))}
});

export default connect(mapStateToProps, mapDispatchToProps)(GameTable);