import React from "react";
import MenuNaviContainer from "../../Container/MenuNaviContainer";
import NewKanaListContainer from "../../Container/Kana/NewKanaListContainer";
import OldKanaList from "./OldKanaList";

export const HANDKANASTER = "1";
export const JOKERKANASTER = "2";
export const JOKERHANDKANSTER = "3";

class Kana extends React.Component {


    render() {
        return (
            <React.Fragment>
                <MenuNaviContainer/>

                <fieldset>
                    <NewKanaListContainer/>
                </fieldset>
                <fieldset>
                    <OldKanaList/>
                </fieldset>
            </React.Fragment>
        );
    }

}

export default Kana;

