import {connect} from "react-redux";
import GameAdder from "../../Components/Skat/GameAdder";
import {addGameFlag, sendListEntry} from "../../Actions";


const mapStateToProps = state => {
    return {
        gameList: state.ListReducer.gameList,
        listId: state.PropertyReducer.listId,
        playerCount: state.PropertyReducer.playerCount,
        player1: state.PropertyReducer.player1,
        player2: state.PropertyReducer.player2,
        player3: state.PropertyReducer.player3,
        player4: state.PropertyReducer.player4
    }
};

const mapDispatchToProps = dispatch => ({
    sendListEntry: (entry) => { dispatch(sendListEntry(entry))    },
    changeGameFlag: (flag) => { dispatch(addGameFlag(flag))    }
});

export default connect(mapStateToProps, mapDispatchToProps)(GameAdder);