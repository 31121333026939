import React from "react";
import PlayerSelector from "./PlayerSelector";
import MenuNaviContainer from "../../Container/MenuNaviContainer";
import StatistikContentContainer from "../../Container/Statistik/StatistikContentContainer";

class StatistikMain extends React.Component {


    render() {
        return (
            <React.Fragment>
                <fieldset>
                    <PlayerSelector/>
                </fieldset>
                <fieldset>
                    <StatistikContentContainer/>
                </fieldset>

                <MenuNaviContainer/>
            </React.Fragment>
        );
    }

}

export default StatistikMain;

