import {connect} from "react-redux";
import Game from "../../Components/Skat/Game";


const mapStateToProps = state => {
    return {
        addGameFlag: state.PropertyReducer.addGameFlag
    }
};

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps,mapDispatchToProps)(Game);