import ReactDOM from 'react-dom';
import React from 'react';
import {createStore, applyMiddleware} from 'redux';
import {Provider} from "react-redux";
import thunk from 'redux-thunk';

import Reducer from "./Reducer";
import AppContainer from './Container/AppContainer';

const store = createStore(Reducer, applyMiddleware(thunk));

ReactDOM.render(
    <Provider store={store}>
        <AppContainer/>
    </Provider>,
    document.getElementById('root'));
