const RE_PARTEI = "RE_PARTEI";
const KON_PARTEI = "KON_PARTEI";
const KEINE_PARTEI = "KEINE_PARTEI";
const SCHW = 6;
const U30 = 5;
const U60 = 4;
const U90 = 3;
const NICHTS = 0;

//-----------------------------------
//-----------FULL LIST---------------
export function sumPoints(dokoList, playerCount) {

    let gameArray = [];
    let old = [];


    for (let i = 0; i < dokoList.length; i++) {
        const entry = dokoList[i];
        const points = reFinalPoints(entry.spiel, entry.re_punkte, entry.re_ansagen, entry.re_K,
            entry.re_D, entry.re_F, entry.k_ansagen, entry.k_K, entry.k_D, entry.k_F);

        if (i === 0) { //first game, just note values
            if (isSolo(entry.spiel)) {
                gameArray.push([
                    playerCount === "5" ? 0 : (entry.player1 === "1" ? points : -points / 3),
                    entry.player1 === "2" ? points : -points / 3,
                    entry.player1 === "3" ? points : -points / 3,
                    entry.player1 === "4" ? points : -points / 3,
                    entry.player1 === "5" ? points : -points / 3
                ]);

            } else {
                gameArray.push([
                    playerCount === "5" ? 0 : ((entry.player1 === "1" || entry.player2 === "1") ? points : -points),
                    (entry.player1 === "2" || entry.player2 === "2") ? points : -points,
                    (entry.player1 === "3" || entry.player2 === "3") ? points : -points,
                    (entry.player1 === "4" || entry.player2 === "4") ? points : -points,
                    (entry.player1 === "5" || entry.player2 === "5") ? points : -points
                ]);
            }
        } else { // not first game, sum up values
            if (isSolo(entry.spiel)) {
                gameArray.push([
                    entry.player1 === "1" ? old[0] + points : old[0] - points / 3,
                    entry.player1 === "2" ? old[1] + points : old[1] - points / 3,
                    entry.player1 === "3" ? old[2] + points : old[2] - points / 3,
                    entry.player1 === "4" ? old[3] + points : old[3] - points / 3,
                    entry.player1 === "5" ? old[4] + points : old[4] - points / 3
                ]);
            } else {
                gameArray.push([
                    (entry.player1 === "1" || entry.player2 === "1") ? old[0] + points : old[0] - points,
                    (entry.player1 === "2" || entry.player2 === "2") ? old[1] + points : old[1] - points,
                    (entry.player1 === "3" || entry.player2 === "3") ? old[2] + points : old[2] - points,
                    (entry.player1 === "4" || entry.player2 === "4") ? old[3] + points : old[3] - points,
                    (entry.player1 === "5" || entry.player2 === "5") ? old[4] + points : old[4] - points
                ]);
            }
            if (playerCount === "5") {
                gameArray[i][(i % 5)] = old[(i % 5)];
            } else {
                gameArray[i][4] = -10000;
            }
        }

        old[0] = gameArray[i][0];
        old[1] = gameArray[i][1];
        old[2] = gameArray[i][2];
        old[3] = gameArray[i][3];
        old[4] = gameArray[i][4];
    }

    return gameArray;
}

export function placement(pointArr, index) {
    let place = 1;
    for (let i = 0; i < pointArr.length; i++) {
        if (pointArr[i] > pointArr[index]) {
            place = place + 1;
        }
    }
    return "--" + place + "--";
}

//-----------------------------------
//-----------ENTRY-------------------
export function isSolo(spiel) {
    if (spiel.toLowerCase() === "normalspiel" || spiel.toLowerCase() === "armut" || spiel.toLowerCase() === "hochzeit") {
        return false;
    }
    return true;
}

function calculateWinner(re_punkte, re_ansagen, k_ansagen) {

    re_punkte = parseInt(re_punkte);
    re_ansagen = parseInt(re_ansagen);
    k_ansagen = parseInt(k_ansagen);

    switch (true) {
        case (re_punkte === 240):
            return RE_PARTEI;
        case(re_punkte > 210):
            if (re_ansagen < SCHW) {
                return RE_PARTEI;
            }
            if (re_ansagen === SCHW && k_ansagen < U90) {
                return KON_PARTEI;
            }
            return KEINE_PARTEI;
        case(re_punkte > 180):
            if (re_ansagen < U30) {
                return RE_PARTEI;
            }
            if (re_ansagen >= U30 && k_ansagen < U90) {
                return KON_PARTEI;
            }
            return KEINE_PARTEI;
        case(re_punkte > 150):
            if (re_ansagen < U60) {
                return RE_PARTEI;
            }
            if (re_ansagen >= U60 && k_ansagen < U90) {
                return KON_PARTEI;
            }
            return KEINE_PARTEI;
        case(re_punkte > 120):
            if (re_ansagen < U90) {
                return RE_PARTEI;
            }
            if (re_ansagen >= U90 && k_ansagen < U90) {
                return KON_PARTEI;
            }
            return KEINE_PARTEI;
        case(re_punkte === 120):
            if (re_ansagen >= NICHTS && k_ansagen === NICHTS) {
                return KON_PARTEI;
            }
            if (re_ansagen === NICHTS && k_ansagen > NICHTS) {
                return RE_PARTEI;
            }
            return KEINE_PARTEI;
        case(re_punkte > 90):
            if (k_ansagen < U90) {
                return KON_PARTEI;
            }
            if (k_ansagen >= U90 && re_ansagen < U90) {
                return RE_PARTEI;
            }
            return KEINE_PARTEI;
        case(re_punkte > 60):
            if (k_ansagen < U60) {
                return KON_PARTEI;
            }
            if (k_ansagen >= U60 && re_ansagen < U90) {
                return RE_PARTEI;
            }
            return KEINE_PARTEI;
        case(re_punkte > 30):
            if (k_ansagen < U30) {
                return KON_PARTEI;
            }
            if (k_ansagen >= U30 && re_ansagen < U90) {
                return RE_PARTEI;
            }
            return KEINE_PARTEI;
        case(re_punkte > 0):
            if (k_ansagen < SCHW) {
                return KON_PARTEI;
            }
            if (k_ansagen === SCHW && re_ansagen < U90) {
                return RE_PARTEI;
            }
            return KEINE_PARTEI;
        case(re_punkte === 0):
            return KON_PARTEI;
        default:
            console.log("Fehler bei Siegerberechnung :c");
    }
}

//An-Absagen-Punkte der Re-Partei
function reStandardPoints(spiel, winner, re_punkte, re_ansagen, k_ansagen) {

    re_punkte = parseInt(re_punkte);
    re_ansagen = parseInt(re_ansagen);
    k_ansagen = parseInt(k_ansagen);
    let points = 0;
    let spielpunkte, meineAnsagen, gegnerAnsagen;

    if (winner === KEINE_PARTEI) {
        if (re_punkte > 210) {
            points += 1;
        }
        if (re_punkte > 180) {
            points += 1;
        }
        if (re_punkte > 150) {
            points += 1;
        }
        if (re_punkte < 90) {
            points -= 1;
        }
        if (re_punkte < 60) {
            points -= 1;
        }
        if (re_punkte < 30) {
            points -= 1;
        }

        if (re_punkte >= 120 && k_ansagen >= U90) {
            points += 1;
        }
        if (re_punkte >= 90 && k_ansagen >= U60) {
            points += 1;
        }
        if (re_punkte >= 60 && k_ansagen >= U30) {
            points += 1;
        }
        if (re_punkte >= 30 && k_ansagen === SCHW) {
            points += 1;
        }

        if (240 - re_punkte >= 120 && re_ansagen >= U90) {
            points -= 1;
        }
        if (240 - re_punkte >= 90 && re_ansagen >= U60) {
            points -= 1;
        }
        if (240 - re_punkte >= 60 && re_ansagen >= U30) {
            points -= 1;
        }
        if (240 - re_punkte >= 30 && re_ansagen === SCHW) {
            points -= 1;
        }

        return points;

    } else /*someone won*/ {
        if (winner === KON_PARTEI) {
            spielpunkte = 240 - re_punkte;
            meineAnsagen = k_ansagen;
            gegnerAnsagen = re_ansagen;
        } else {
            spielpunkte = re_punkte;
            meineAnsagen = re_ansagen;
            gegnerAnsagen = k_ansagen;
        }

        points = 1;
        if (winner === KON_PARTEI && !isSolo(spiel)) {
            points += 1;
        }

        points += meineAnsagen;
        points += gegnerAnsagen;

        if (spielpunkte > 150) {
            points += 1;
        }
        if (spielpunkte > 180) {
            points += 1;
        }
        if (spielpunkte > 210) {
            points += 1;
        }
        if (spielpunkte === 240) {
            points += 1;
        }

        if (spielpunkte >= 120 && gegnerAnsagen >= U90) {
            points += 1;
        }
        if (spielpunkte >= 90 && gegnerAnsagen >= U60) {
            points += 1;
        }
        if (spielpunkte >= 60 && gegnerAnsagen >= U30) {
            points += 1;
        }
        if (spielpunkte >= 30 && gegnerAnsagen === SCHW) {
            points += 1;
        }

        return winner === RE_PARTEI ? points : (0 - points);
    }

}

// + Karlchen, Doko, Füchse, Solix3
export function reFinalPoints(spiel, re_punkte, re_ansagen, re_K, re_D, re_F, k_ansagen, k_K, k_D, k_F) {

    let winner = calculateWinner(re_punkte, re_ansagen, k_ansagen);
    let points = reStandardPoints(spiel, winner, re_punkte, re_ansagen, k_ansagen);

    if (isSolo(spiel)) {
        points *= 3;
    } else {
        points += (parseInt(re_K) + parseInt(re_F) + parseInt(re_D));
        points -= (parseInt(k_K) + parseInt(k_F) + parseInt(k_D));
    }

    return points;
}