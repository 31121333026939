import {connect} from "react-redux";
import OldDokoListItems from "../../Components/Doko/OldDokoListItems";
import {retrieveDokoList, retrieveOldDokoIds} from "../../Actions";


const mapStateToProps = state => {
    return {
        accountName: state.PropertyReducer.accountName,
        dokoListIds: state.PropertyReducer.dokoListIds,
    }
};

const mapDispatchToProps = dispatch => ({
    retrieveOldDokoIds: (accountName) => { dispatch(retrieveOldDokoIds(accountName))},
    retrieveDokoList: (listId) => { dispatch(retrieveDokoList(listId))},
});

export default connect(mapStateToProps,mapDispatchToProps)(OldDokoListItems);