import React from "react";
import {sumKanaPoints} from "./KanaTableCalculations";
import {HANDKANASTER, JOKERHANDKANSTER, JOKERKANASTER} from "./Kana";


class KanaTableBody extends React.Component {

    render() {

        const gameArray = sumKanaPoints(this.props.kanaList);

        return (
            <React.Fragment>
                {this.props.kanaList.map((entry, index) => {
                    return (
                        <tr key={"kanaList_" + index}
                            className="geberLinesFour"
                        >
                            <td className="borderRight darkCell">{entry.gameNumber}&nbsp;
                                {entry.gameNumber === this.props.kanaList.length && this.props.addGameFlag === false
                                    ? <i
                                        className="fas fa-trash-alt"
                                        id = "littleIcon"
                                        onClick={() => {
                                            if(window.confirm("Letzten Eintrag löschen?")) {
                                                this.props.deleteKanaEntry(this.props.listId, entry.gameNumber)}
                                        }}
                                        style={{fontSize: '10px', cursor:'pointer'}}/>
                                    : <div/>
                                }
                            </td>
                            <td>
                                {parseInt(entry.rein1)*500}
                                {entry.sonstiges1 === JOKERHANDKANSTER || entry.sonstiges1 === JOKERKANASTER
                                    ? <span className="jokerKanaster"> + 1k</span> : ""}
                            </td>
                            <td>{parseInt(entry.unrein1)*300}</td>
                            <td>-{entry.hand1}</td>
                            <td>+{entry.tisch1}</td>
                            <td className="borderRight">{entry.dreier1}</td>
                            <td className={entry.sonstiges1 === HANDKANASTER || entry.sonstiges1 === JOKERHANDKANSTER
                            ? "handKanaster geberLinesFour darkCell" : "geberLinesFour darkCell"}>
                                {gameArray[entry.gameNumber - 1].player1 || "-"}
                            </td>
                            <td className="borderRight darkCell">{gameArray[entry.gameNumber - 1].gesamt1 || "-"}</td>

                            <td className="darkCell">{gameArray[entry.gameNumber - 1].gesamt2 || "-"}</td>
                            <td className={entry.sonstiges2 === HANDKANASTER || entry.sonstiges2 === JOKERHANDKANSTER
                                ? "handKanaster geberLinesFour darkCell" : "geberLinesFour darkCell"}>
                                {gameArray[entry.gameNumber - 1].player2 || "-"}
                            </td>
                            <td className="borderLeft">{entry.dreier2}</td>
                            <td>+{entry.tisch2}</td>
                            <td>-{entry.hand2}</td>
                            <td>{parseInt(entry.unrein2)*300}</td>
                            <td>
                                {parseInt(entry.rein2)*500}
                                {entry.sonstiges2 === JOKERHANDKANSTER || entry.sonstiges2 === JOKERKANASTER
                                    ? <span className="jokerKanaster"> + 1k</span> : ""}
                            </td>
                        </tr>
                    );
                })}
            </React.Fragment>
        );
    }

}

export default KanaTableBody;

