import {connect} from "react-redux";
import Kana from "../../Components/Kana/Kana";


const mapStateToProps = state => {
    return {
        //addGameFlag: state.PropertyReducer.addGameFlag
    }
};

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps,mapDispatchToProps)(Kana);