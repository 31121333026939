import {connect} from "react-redux";
import {retrieveListId} from "../../Actions";
import NewDokoList from "../../Components/Doko/NewDokoList";


const mapStateToProps = state => {
    return {
        accountName: state.PropertyReducer.accountName,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        retrieveListId: metaData => dispatch(retrieveListId(metaData)),
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(NewDokoList);