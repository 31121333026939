import React from "react";


class AddDokoEntry extends React.Component {

    state = {
        spiel: "Normalspiel",
        gameNumber: this.props.dokoList.length + 1,
        listId: this.props.listId,
        player1: "",
        player2: "",
        re_ansagen: "0",
        re_F: "0",
        re_D: "0",
        re_K: "0",
        re_punkte: "120",
        k_ansagen: "0",
        k_F: "0",
        k_D: "0",
        k_K: "0",
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.sendDokoEntry(this.state);
        this.props.changeGameFlag(false);
    };

    geber = this.props.playerCount === '4' ? (this.state.gameNumber - 1) % 4 + 1 : (this.state.gameNumber - 1) % 5 + 1;
    reSpielerCount = () => {
        if (this.state.spiel === "Normalspiel" || this.state.spiel === "Hochzeit" || this.state.spiel === "Armut") {
            return 2;
        }
        return 1
    };
    numberImg = (number) => {
        switch (number) {
            case "0":
                return <img src="./images/numbers/zero.png"
                            className="adderNumber"
                            alt="0"/>;
            case "1":
                return <img src="./images/numbers/one.png"
                            className="adderNumber"
                            alt="1"/>;
            case "2":
                return <img src="./images/numbers/two.png"
                            className="adderNumber"
                            alt="2"/>;
            case "3":
                return <img src="./images/numbers/three.png"
                            className="adderNumber"
                            alt="3"/>;
            case "4":
                return <img src="./images/numbers/four.png"
                            className="adderNumber"
                            alt="4"/>;
            default:
                return <div/>;
        }
    };

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="flexAdderContainer">
                    <div className="form-group form-group-inline">
                        <select name="game"
                                className={"form-control"}
                                value={this.state.spiel}
                                onChange={(e) => this.setState({
                                    spiel: e.target.value,
                                    player2: this.reSpielerCount === 1 ? "" : this.state.player2
                                })}>
                            <option value="Normalspiel">Normalspiel</option>
                            <option value="Hochzeit">Hochzeit</option>
                            <option value="Armut">Armut</option>
                            <option value="Fleischloser">Fleischloser</option>
                            <option value="SoloHochzeit">Solo-Hochzeit</option>
                            <option value="Pflichtsolo">Pflicht-Solo</option>
                            <option value="Farbsolo">Farb-Solo</option>
                            <option value="Damensolo">Damen-Solo</option>
                            <option value="Bubensolo">Buben-Solo</option>
                        </select>
                    </div>
                    <div className={"form-group form-group-inline"}>
                        <select name="spieler"
                                className={"form-control"}
                                required
                                value={this.state.player1}
                                onChange={(e) => this.setState(
                                    {
                                        player1: e.target.value,
                                        player2: this.state.player2 === e.target.value ? "" : this.state.player2
                                    })}>
                            <option value="" disabled>Spieler wählen</option>
                            {this.geber === 1 && this.props.playerCount === "5" ? "" :
                                <option value="1">{this.props.player1}</option>}
                            {this.geber === 2 && this.props.playerCount === "5" ? "" :
                                <option value="2">{this.props.player2}</option>}
                            {this.geber === 3 && this.props.playerCount === "5" ? "" :
                                <option value="3">{this.props.player3}</option>}
                            {this.geber === 4 && this.props.playerCount === "5" ? "" :
                                <option value="4">{this.props.player4}</option>}
                            {this.geber === 5 || this.props.playerCount === '4' ? "" :
                                <option value="5">{this.props.player5}</option>}
                        </select>
                    </div>
                    {this.reSpielerCount() === 1 ? <div/> :
                        <div className={"form-group form-group-inline"}>
                            <select required
                                    className={"form-control"}
                                    value={this.state.player2}
                                    onChange={(e) => this.setState({player2: e.target.value})}>
                                <option value="" disabled>Spieler wählen</option>
                                {(this.geber === 1 && this.props.playerCount === "5") || this.state.player1 === "1" ? "" :
                                    <option value="1">{this.props.player1}</option>}
                                {(this.geber === 2 && this.props.playerCount === "5") || this.state.player1 === "2" ? "" :
                                    <option value="2">{this.props.player2}</option>}
                                {(this.geber === 3 && this.props.playerCount === "5") || this.state.player1 === "3" ? "" :
                                    <option value="3">{this.props.player3}</option>}
                                {(this.geber === 4 && this.props.playerCount === "5") || this.state.player1 === "4" ? "" :
                                    <option value="4">{this.props.player4}</option>}
                                {this.geber === 5 || this.state.player1 === "5" || this.props.playerCount === '4' ? "" :
                                    <option value="5">{this.props.player5}</option>}
                            </select>
                        </div>
                    }
                </div>
                <div className={"flexAdderContainer"}>
                    <div className="form-group">
                        <label htmlFor="Repartei" className={"form-label"}>Re-Partei</label>
                        <select name="Repartei"
                                className={"form-control"}
                                value={this.state.re_ansagen}
                                onChange={(e) => this.setState({re_ansagen: e.target.value})}>
                            <option value="0">keine Ansagen</option>
                            <option value="2">Re angesagt</option>
                            <option value="3">U90 angesagt</option>
                            <option value="4">U60 angesagt</option>
                            <option value="5">U30 angesagt</option>
                            <option value="6">schwarz angesagt</option>
                        </select>
                    </div>
                    <div className="form-group" onClick={() => {
                        this.setState({
                            re_F: this.state.re_F === "2" ? "0"
                                : (parseInt(this.state.re_F) + 1).toString()
                        })
                    }}>
                        <label className="form-label">
                            Fu
                        </label>
                        <div className={"form-control"}>
                            {this.numberImg(this.state.re_F)}
                        </div>
                    </div>
                    <div className="form-group" onClick={() => {
                        this.setState({
                            re_D: this.state.re_D === "4" ? "0"
                                : (parseInt(this.state.re_D) + 1).toString()
                        })
                    }}>
                        <label className="form-label">
                            Do
                        </label>
                        <div className={"form-control"}>
                            {this.numberImg(this.state.re_D)}
                        </div>
                    </div>
                    <div className="form-group" onClick={() => {
                        this.setState({
                            re_K: this.state.re_K === "2" ? "0"
                                : (parseInt(this.state.re_K) + 1).toString()
                        })
                    }}>
                        <label className="form-label">
                            Ka
                        </label>
                        <div className={"form-control"}>
                            {this.numberImg(this.state.re_K)}
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="Re_Pkt" className={"form-label"}>Re Punkte</label>
                        <input name="Re_Pkt"
                               className={"form-control"}
                               type="number" min="0" max="240"
                               value={this.state.re_punkte}
                               onChange={(e) => this.setState({re_punkte: e.target.value})}
                        />
                    </div>
                </div>


                <div className={"flexAdderContainer"}>
                    <div className={"form-group"}>
                        <label htmlFor="Kpartei" className={"form-label"}>Kontra-Partei</label>
                        <select name="Kpartei"
                                className={"form-control"}
                                value={this.state.k_ansagen}
                                onChange={(e) => this.setState({k_ansagen: e.target.value})}>
                            <option value="0">keine Ansagen</option>
                            <option value="2">Kontra angesagt</option>
                            <option value="3">U90 angesagt</option>
                            <option value="4">U60 angesagt</option>
                            <option value="5">U30 angesagt</option>
                            <option value="6">schwarz angesagt</option>
                        </select>
                    </div>
                    <div className={"form-group"} onClick={() => {
                        this.setState({
                            k_F: this.state.k_F === "2" ? "0"
                                : (parseInt(this.state.k_F) + 1).toString()
                        })
                    }}>
                        <label className="form-label">
                            Fu
                        </label>
                        <div className={"form-control"}>
                            {this.numberImg(this.state.k_F)}
                        </div>
                    </div>
                    <div className={"form-group"} onClick={() => {
                        this.setState({
                            k_D: this.state.k_D === "4" ? "0"
                                : (parseInt(this.state.k_D) + 1).toString()
                        })
                    }}>
                        <label className="form-label">
                            Do
                        </label>
                        <div className={"form-control"}>
                            {this.numberImg(this.state.k_D)}
                        </div>
                    </div>
                    <div className={"form-group"} onClick={() => {
                        this.setState({
                            k_K: this.state.k_K === "2" ? "0"
                                : (parseInt(this.state.k_K) + 1).toString()
                        })
                    }}>
                        <label className="form-label">
                            Ka
                        </label>
                        <div className={"form-control"}>
                            {this.numberImg(this.state.k_K)}
                        </div>
                    </div>
                    <div className={"form-group"}>
                        <label htmlFor="Re_Pkt" className={"form-label"}>Kontra Punkte</label>
                        <input name="Re_Pkt"
                               className={"form-control"}
                               type="number" min="0" max="240"
                               value={240 - this.state.re_punkte}
                               onChange={(e) => this.setState({re_punkte: 240 - e.target.value})}
                        />
                    </div>
                </div>
                <div className={"form-group"} style={{padding: "5px"}}>
                    <button
                        type="submit"
                        className="btn btn-success"
                        style={{width: "100%"}}
                    >
                        <i className="fas fa-file-medical"/> Eintrag Speichern
                    </button>
                </div>
            </form>
        )
            ;
    }

}

export default AddDokoEntry;

