import MMNewList from "../../Components/Skat/MMNewList";
import {connect} from "react-redux";
import {retrieveListId, setMenuFlag} from "../../Actions";


const mapStateToProps = state => {
    return {
            accountName: state.PropertyReducer.accountName,
            playerCount: state.PropertyReducer.playerCount,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setMenuFlag: menuFlag => dispatch(setMenuFlag(menuFlag)),
        retrieveListId: metaData => dispatch(retrieveListId(metaData))
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(MMNewList);