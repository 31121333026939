import React from "react";
import AddDokoEntryContainer from "../../Container/Doko/AddDokoEntryContainer";
import DokoTableHeaderContainer from "../../Container/Doko/DokoTableHeaderContainer";
import DokoTableBodyContainer from "../../Container/Doko/DokoTableBodyContainer";

import "./DokoTable.scss";
import MenuNaviContainer from "../../Container/MenuNaviContainer";

class DokoTable extends React.Component {

    render() {
        return (
            <React.Fragment>
                <MenuNaviContainer/>
                {this.props.addGameFlag ? <AddDokoEntryContainer/> : <div/>}
                <div>
                <table className={"dokoTable"}>
                    <DokoTableHeaderContainer/>
                    <tbody>
                    <DokoTableBodyContainer/>
                    </tbody>
                </table>
                </div>
            </React.Fragment>
        );
    }

}

export default DokoTable;

